import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__MECHANISM_CATEGORY__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Názov kategórie mechanizmu"
          />
        }
        required
      />
      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
}
