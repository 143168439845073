import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useMechanismCategories } from '@modules/dict__mechanism-category/mechanism-category-api';

export function Fields() {
  const mechanismCategories = useMechanismCategories();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné údaje"
          />
        }
      >
        <DictionaryEvidence.FieldOrder />
        <FormAutocomplete
          name="category"
          source={mechanismCategories}
          required={true}
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__CATEGORY"
              defaultMessage="Kategória mechanizmu"
            />
          }
        />
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__LICENCE_PLATE"
              defaultMessage="Mechanizumus"
            />
          }
          required
        />
        <FormTextField
          name="licencePlate"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__LICENCE_PLATE"
              defaultMessage="ŠPZ"
            />
          }
          helpLabel="Ak neexistuje ŠPZ, zadajte označenie / sériové číslo."
          required
        />

        <FormTextField
          name="subSupplier"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__SUB_SUPPLIER"
              defaultMessage="Dodávateľ mechanismu"
            />
          }
          required
        />

        <FormTextField
          name="externalId"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__DETAIL__FIELD_LABEL__EXTERNAL_ID"
              defaultMessage="Externý identifikátor"
            />
          }
          disabled
        />
        <DictionaryEvidence.FieldActive />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
