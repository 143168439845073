import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FormAutocomplete,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { WorkGroup } from '@models';
import { personLabelMapper } from '@utils/label-mapper';

export function PersonAssignDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<WorkGroup>>(DetailContext);

  const allAssignedMembersIds = (data?.members ?? []).map((p) => p.id);

  const persons = usePersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allAssignedMembersIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <FormAutocomplete
        name="members"
        label={
          <FormattedMessage
            id="ESD__PERSON__COLLECTION__FIELD_LABEL__PERSON"
            defaultMessage="Osoba"
          />
        }
        required
        labelMapper={personLabelMapper}
        source={persons}
      />
    </>
  );
}
