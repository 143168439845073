import { PersonAutocomplete, PersonType, UserAutocomplete } from '@models';

export const personLabelMapper = (value?: PersonAutocomplete | PersonType) => {
  return `${value?.name ? value?.name + ' ' : ''}${value?.surname ?? ''}${
    value?.kid
      ? `, KID: ${value.kid}`
      : value?.personalNumber
      ? ` (${value.personalNumber})`
      : ''
  }`;
};
export const userLabelMapper = (value?: UserAutocomplete) => {
  return personLabelMapper(value?.person);
};

export const userColumnLabelMapper = ({
  value,
}: {
  value: UserAutocomplete;
}) => {
  return userLabelMapper(value);
};
userLabelMapper.displayName = 'userColumnLabelMapper';

export const personColumnLabelMapper = ({
  value,
}: {
  value: PersonAutocomplete;
}) => {
  return personLabelMapper(value);
};
personLabelMapper.displayName = 'personColumnLabelMapper';
