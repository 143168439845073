import { useStaticListSource } from '@eas/common-web';
import { DictionaryAutocomplete } from '@models';
import { AssemblyType } from '@enums';

export function useAssemblyTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: AssemblyType.ASSEMBLY, name: 'Montáž' },
    { id: AssemblyType.DISASSEMBLY, name: 'Demontáž' },
    { id: AssemblyType.REASSEMBLY, name: 'Znovumontáž' },
  ]);
}
