import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { Me, PartialJournal } from '@models';
import { EvidenceApiUrl, PartialJournalState, Permission } from '@enums';
import { useUserAssignments } from '@utils/use-user-assignments';

export function useReopenPartialJournalDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const callApi = (journalId: string, formValues: { reason: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.PARTIAL_JOURNAL}/${journalId}/reopen`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues.reason,
      }
    );
  };

  const showButton = data?.state === PartialJournalState.CLOSED; /* &&
    hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_REOPEN) &&
    isPrimaryJournalAssigned(data?.id); */
  return { callApi, showButton };
}
