import { useContext } from 'react';
import { PartialJournalState } from 'src/enums/partial-journal-state';
import { DetailContext, UserContext, abortableFetch } from '@eas/common-web';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { useIsAdmin } from '@utils/use-is-admin';

export function useCancelPartialJournalDialog() {
  const { hasPermission } = useContext(UserContext);
  const {
    source: { data },
  } = useContext(DetailContext);

  const isAdmin = useIsAdmin();

  const isRequestCancel =
    data?.state === PrimaryJournalState.CLOSED && !isAdmin;

  const callApi = (journalId: string, formValues: { reason: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.PARTIAL_JOURNAL}/${journalId}/cancel${
        isRequestCancel ? '/request' : ''
      }`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues?.reason,
      }
    );
  };
  const showButton =
    data?.parent?.state !== PrimaryJournalState.CANCELLED &&
    data?.state !== PartialJournalState.SIGNED &&
    data?.state !== PartialJournalState.CANCELLED &&
    (hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_CANCEL) ||
      hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_CANCEL_REQUEST));
  return { callApi, showButton, isRequestCancel };
}
