import * as Yup from 'yup';
import { Address, Investor } from '@models';

export function useValidationSchema() {
  return Yup.object<Investor>().shape({
    name: Yup.string().nullable().required(),
    ico: Yup.string()
      .nullable()
      .required()
      .test(
        'ico',
        'IČO nie je v správnom formáte. Zadajte 8 číslic bez medzier.',
        (value) => !!value && /^\d{8}$/.test(value)
      ),
    address: Yup.object<Address>().shape({
      municipality: Yup.string().nullable().required(),
      region: Yup.string().nullable().required(),
      street: Yup.string().nullable(),
      zipCode: Yup.string()
        .nullable()
        .test(
          'psc',
          'PSČ nie je v správnom formáte',
          (value) => !value || value.length === 5 || value.length === 6
        ),
    }),
  });
}
