import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const SUPPLIER_SWITCH_SUPPLIER: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__SUCCESS"
      id="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__SUCCESS"
      defaultMessage="Zastupovanie dodávateľa bolo úspešne aktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__ERROR"
      id="ESD__MESSAGE__USER__CONTEXT_SUPPLIER_SWITCH_SUPPLIER__ERROR"
      defaultMessage="Prepínanie kontextu zlyhalo. Obnovte stránku. Ak problém pretrváva, kontaktujte administrátora."
    />,
    SnackbarVariant.ERROR,
  ],
};

const SUPPLIER_SWITCH_TENANT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_TENANT__SUCCESS"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_TENANT__SUCCESS"
      defaultMessage="Zastupovanie vlastníka bolo úspešne aktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_TENANT__ERROR"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_SWITCH_TENANT__ERROR"
      defaultMessage="Pri aktivovaní zástupu vlastníka nastala chyba."
    />,
    SnackbarVariant.ERROR,
  ],
};

const SUPPLIER_REMOVE_TENANT: MessageType = {
  SUCCESS: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_TENANT__SUCCESS"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_TENANT__SUCCESS"
      defaultMessage="Zastupovanie vlastníka bolo úspešne deaktivované."
    />,
    SnackbarVariant.SUCCESS,
  ],
  ERROR: [
    <FormattedMessage
      key="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_TENANT__ERROR"
      id="ESD__MESSAGE__USER__SUPPLIER_CONTEXT_REMOVE_TENANT__ERROR"
      defaultMessage="Pri deaktivácii zástupu vlastníka nastala chyba."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default {
  SUPPLIER_SWITCH_SUPPLIER,
  SUPPLIER_SWITCH_TENANT,
  SUPPLIER_REMOVE_TENANT,
};
