import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Supplier } from '@models';

export function useColumns(): TableColumn<Supplier>[] {
  const intl = useIntl();
  const {
    columnName,
    columnOrder,
    columnValidFrom,
    columnValidTo,
    columnActive,
  } = DictionaryEvidence.useDictionaryColumns<Supplier>();

  return [
    /*     {
      datakey: 'tenant',
      filterkey: 'tenant.id',
      sortkey: 'tenant.name',
      name: intl.formatMessage({
        id: 'ESD__SUPPLIER__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    }, */
    columnName,
    {
      datakey: 'ico',
      name: intl.formatMessage({
        id: 'ESD_SUPPLIER__COLUMN__ICO',
        defaultMessage: 'IČO',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },
    {
      datakey: 'dic',
      name: intl.formatMessage({
        id: 'ESD_SUPPLIER__COLUMN__DIC',
        defaultMessage: 'DIČ',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },
    columnOrder,
    columnValidFrom,
    columnValidTo,
    columnActive,
  ];
}
