import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Me, OtherRecord } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const INSTRUCTED_PERSON = 'INSTRUCTED_PERSON';
export const INSPECTOR = 'INSPECTOR';
export const FILES = 'FILES';

function useInstructedPersonSource(): HookedSource<OtherRecord> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [INSTRUCTED_PERSON]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryOtherRecord.JOURNAL_ENTRY_OTHER_RECORD_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS}/person/list`
        );
        source.setParams({
          // We set here -1, so we get all instructed person in person assign dialog. If this causes problems (if there will be a lot of people),
          // set here size: 15 and use useFetch in dialog or disable filering functionality there.
          size: -1,
          filters: [
            {
              field: 'otherRecord.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useInspectorsSource(): HookedSource<OtherRecord> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [INSPECTOR]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryOtherRecord.JOURNAL_ENTRY_OTHER_RECORD_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS}/inspector/list`
        );
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'otherRecord.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useFilesSource(): HookedSource<OtherRecord> {
  const source = useScrollableSource();

  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [FILES]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.JournalEntryOtherRecord.JOURNAL_ENTRY_OTHER_RECORD_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(
          `${EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS}/file/list`
        );
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'otherRecord.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useOtherRecordsHookedSources(): HookedSource<OtherRecord> {
  const instructedPersons = useInstructedPersonSource();
  const inspectors = useInspectorsSource();
  const files = useFilesSource();

  return {
    ...instructedPersons,
    ...inspectors,
    ...files,
  };
}
