import React, { useContext } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  ActionButton,
  DetailContext,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceContext,
  FormTextArea,
  NavigationContext,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useRemoveButtonSchema } from '@components/detail-toolbar-remove-button/remove-button-schema';
import {
  EvidenceBrowserUrl,
  JournalEntryState,
  Messages,
  Permission,
} from '@enums';
import { useIsAdmin } from '@utils/use-is-admin';

interface BozpDetailRemoveButtonProps<T extends 'BOZP' | 'OTHER_RECORDS'> {
  type: T;
  directDeletePermission: T extends 'BOZP'
    ? keyof typeof Permission.JournalEntryBOZP
    : string; // TODO: Add oermission journalentryOtherRecords
  requestDeletePermission: keyof typeof Permission.JournalEntryBOZP;
}

const BozpDetailRemoveButton = <T extends 'BOZP' | 'OTHER_RECORDS'>({
  type,
  directDeletePermission,
  requestDeletePermission,
}: BozpDetailRemoveButtonProps<T>) => {
  const {
    del,
    source: { data },
    refresh,
  } = useContext(DetailContext);
  const { apiUrl } = useContext(EvidenceContext);
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);

  const isAdmin = useIsAdmin();
  const schema = useRemoveButtonSchema();

  const requestDel = useEventCallback((formValues) => {
    return abortableFetch(`${apiUrl}/${data?.id}/delete/request`, {
      method: 'POST',
      body: JSON.stringify(formValues?.reason),
    });
  });

  const hasAnyDeletePermission =
    hasPermission(directDeletePermission) ||
    hasPermission(requestDeletePermission);

  const hideDeleteButton =
    data?.journalEntry?.state !== JournalEntryState.OPENED;

  if (!data || !hasAnyDeletePermission || hideDeleteButton) return null;

  const isDirectDelete = isAdmin || !data?.signed;

  return (
    <>
      {isDirectDelete ? (
        <ActionButton
          promptKey={`DELETE_DIALOG_${type}`}
          buttonTooltip="Zmazanie"
          buttonLabel="zmazať"
          ButtonComponent={DetailToolbarButton2}
          buttonProps={{
            type: DetailToolbarButtonType.SECONDARY,
            startIcon: <DeleteOutlinedIcon />,
          }}
          submitCallback={async () => {
            try {
              await del();

              navigate(
                `${EvidenceBrowserUrl.JOURNAL_ENTRY}/${
                  data?.journalEntry?.id
                }?view=${
                  type === 'BOZP'
                    ? 'JOURNAL_ENTRY_BOZP'
                    : 'JOURNAL_ENTRY_OTHER_RECORDS'
                }`
              );
              showSnackbar(
                'Záznam bol úspešne zmazaný.',
                SnackbarVariant.SUCCESS,
                true
              );
            } catch (e) {
              showSnackbar(...Messages.Common.UNEXPECTED_ERROR);
            }
          }}
          dialogText="Naozaj chcete zmazať tento záznam? Táto akcia je nevratná."
          dialogTitle="Upozornenie"
        />
      ) : (
        <ActionButton
          promptKey={`DELETE_DIALOG_REQUEST_${type}`}
          buttonTooltip="Žiadosť o zmazanie záznamu"
          buttonLabel="zmazať"
          ButtonComponent={DetailToolbarButton2}
          buttonProps={{
            type: DetailToolbarButtonType.SECONDARY,
            startIcon: <DeleteOutlinedIcon />,
          }}
          apiCall={requestDel}
          successMessage={'Žiadosť o zmazanie bola úspešne odoslaná'}
          onSuccess={async () => {
            refresh();
            showSnackbar(
              'Žiadosť o zmazanie bola odoslaná.',
              SnackbarVariant.SUCCESS
            );
          }}
          FormFields={() => (
            <FormTextArea name="reason" label="Dôvod zmazania" required />
          )}
          dialogText="Dôjde k odoslaniu žiadosti zmazanie záznamu. Ak chcete pokračovať, zadajte dôvod zmazania."
          dialogTitle="Upozornenie"
          formValidationSchema={schema}
          // onBeforeDialog={() => handleTestAction(ActionType.DELETE)}
        />
      )}
    </>
  );
};
export default BozpDetailRemoveButton;
