import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { WorkGroup } from '@models';

export const useColumns = () => {
  const intl = useIntl();

  const { dictionaryColumns } =
    DictionaryEvidence.useDictionaryColumns<WorkGroup>();

  return [
    /*   {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__COLUMN__NAME',
        defaultMessage: 'Názov pracovnej skupiny',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    }, */
    ...dictionaryColumns,
  ] as TableColumn<WorkGroup>[];
};
