import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, EvidenceContext, UserContext } from '@eas/common-web';
import { NOTES } from '@modules/journal-entry/journal-entry-hooked-sources';
import { useMultipleDeleteApi } from '@components/collection-field/collection-api';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me, Note } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { NotesCreateDialog } from './dialogs/notes-create-dialog';
import { useColumns } from './notes-colums';
import { useValidationSchema } from './notes-schema';

type NotesFieldProps = Partial<
  ComponentPropsWithoutRef<typeof CollectionField2<Note>>
>;

export function NotesField({ ...overrides }: NotesFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const source = hookedSources[NOTES]?.source;

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__NOTES"
          defaultMessage="Poznámky"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/note',
        Dialog: NotesCreateDialog,
        initValues: () => ({
          journalEntry: { id: data?.id },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
        hide: !hasPermission(
          Permission.JournalEntryNote.JOURNAL_ENTRY_NOTE_CREATE
        ),
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
        Dialog: NotesCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 650,
        hide: !hasPermission(
          Permission.JournalEntryNote.JOURNAL_ENTRY_NOTE_UPDATE
        ),
      }}
      deleteAction={{
        getUrl: () => `${EvidenceApiUrl.JOURNAL_ENTRY}/note`,
        label: 'Odobrať',
        useApi: useMultipleDeleteApi,
        hide: !hasPermission(
          Permission.JournalEntryNote.JOURNAL_ENTRY_NOTE_DELETE
        ),
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
