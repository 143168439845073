import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  FormCheckbox,
  FormInstantField,
  FormPanel,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { DocumentsField } from '@composite/documents-collection/documents-field';
import { InspectorField } from '@composite/inspector-collection/inspector-field';
import { PersonField2 } from '@composite/person-collection2/person-field2';
import { Redirect } from '@components/redirect/redirect';
import { OtherRecord } from '@models';
import { EvidenceBrowserUrl, JournalEntryState } from '@enums';
import {
  FILES,
  INSPECTOR,
  INSTRUCTED_PERSON,
} from './other-record-hooked-sources';

export const Fields = () => {
  const {
    source: { data },
  } = useContext<DetailHandle<OtherRecord>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const instructedPersonSource = hookedSources[INSTRUCTED_PERSON]?.source;
  const inspectorSource = hookedSources[INSPECTOR]?.source;
  const filesSource = hookedSources[FILES]?.source;

  const journalEntryId = data?.journalEntry?.id;

  const isSigned = !!data?.signed;
  const isJournalEntryClosed =
    data?.journalEntry?.state !== JournalEntryState.OPENED;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__OTHER_RECORDS__DETAIL__PANEL__JOURNAL_ENTRY"
            defaultMessage="Denný záznam"
          />
        }
      >
        <FormInstantField
          name="journalEntry.validityDate"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__DETAIL__PANEL__JOURNAL_ENTRY_DATE"
              defaultMessage="Dátum denného záznamu"
            />
          }
          representation="local-date"
          after={
            journalEntryId && (
              <Redirect
                url={EvidenceBrowserUrl.JOURNAL_ENTRY}
                id={journalEntryId}
              />
            )
          }
          disabled
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__OTHER_RECORDS__DETAIL__PANEL__GENERAL_TAB"
            defaultMessage="Základné"
          />
        }
      >
        <FormCheckbox
          name="signed"
          disabled
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__SIGNED"
              defaultMessage="Ostatný záznam podpísaný"
            />
          }
        />

        <FormTextField
          name="institutionName"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__INSTITUTION_NAME"
              defaultMessage="Názov inštitúcie na stavbe"
            />
          }
          required
        />

        <FormTextField
          name="header"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__HEADER"
              defaultMessage="Hlavička"
            />
          }
          required
        />

        <FormTextArea
          name="description"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
        />
      </FormPanel>

      <InspectorField
        source={inspectorSource}
        disabled={isJournalEntryClosed}
      />

      <PersonField2
        evidence="OTHER_RECORDS"
        source={instructedPersonSource}
        disabled={isJournalEntryClosed}
        exceptions={regionApiErrors.BOZP_PERSON}
      />

      <DocumentsField<OtherRecord>
        source={filesSource}
        evidence="OTHER_RECORDS"
        disabled={isJournalEntryClosed || isSigned}
      />
    </>
  );
};
