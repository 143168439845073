import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DetailContext, FormCustomField, FormFileField } from '@eas/common-web';
import { PrimaryJournalState } from '@enums';

const FinalReportField = () => {
  const {
    source: { data },
  } = useContext(DetailContext);
  return (
    <>
      {data?.state === PrimaryJournalState.CLOSING && !data?.finalReport && (
        <FormCustomField label="Záverečná správa">
          <CircularProgress size="16px" style={{ marginTop: 2 }} />
        </FormCustomField>
      )}
      {data?.finalReport && (
        <FormFileField
          name="finalReport"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__FINAL_REPORT"
              defaultMessage="Záverečná správa"
            />
          }
          disabled
        />
      )}
    </>
  );
};

export default FinalReportField;
