import { useCallback, useContext } from 'react';
import { UserContext, abortableFetch } from '@eas/common-web';
import { Me } from '@models';

export const useDeleteAllDictionaryItems = (
  apiUrl: string,
  removeAllPermission: string
) => {
  const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);

  const callApi = useCallback(
    () =>
      abortableFetch(apiUrl, {
        method: 'DELETE',
      }),
    [apiUrl]
  );

  const showButton = false; /* hasPermission(removeAllPermission); */
  //TODO: V iteraci 3 zprovoznit.

  return {
    callApi,
    showButton,
  };
};
