import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  DetailActionButton,
  FormTextArea,
  FormTextField,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { useSignJournalByKepDialog } from '@components/kep-signature/sign-partial-journal-kep-hook';
import { ErrorObject } from '@models';
import { ActionType, JournalTypeEnum, Messages } from '@enums';
import { useTestAction } from '@utils/use-test-action';
import { useCancelPrimaryJournalDialog } from './dialog-actions/cancel-primary-journal-hook';
import { useClosePrimaryDialog } from './dialog-actions/close-primary-hook';
import { useReopenPrimaryJournalDialog } from './dialog-actions/reopen-primary-journal-hook';
import { useSignPrimaryJournalByTimestamp } from './dialog-actions/sign-timestamp-hook';

export function PrimaryJournalActionbar() {
  const { showSnackbar } = useContext(SnackbarContext);

  const { callApi: closeApiCall, showButton: showCloseButton } =
    useClosePrimaryDialog();

  const {
    callApi: signByTimestampApiCall,
    showButton: showSignByTimeStampButton,
  } = useSignPrimaryJournalByTimestamp();

  const { callApi: signByKepCallback, showButton: showSignByKepButton } =
    useSignJournalByKepDialog(JournalTypeEnum.PRIMARY);

  const { callApi: reopenApiCall, showButton: showReopenButton } =
    useReopenPrimaryJournalDialog();

  const {
    callApi: cancelApiCall,
    showButton: showCancelButton,
    isRequestCancel,
  } = useCancelPrimaryJournalDialog();

  const { handleTestAction } = useTestAction(JournalTypeEnum.PRIMARY);

  return (
    <>
      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__BUTTON"
              defaultMessage="Otvoriť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete znovu otvoriť hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={reopenApiCall}
          ButtonComponent={TertiaryDetailActionbarButton}
          FormFields={() => (
            <FormTextField name="reason" label="Dôvod" required />
          )}
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          isLocked={false}
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
          onBeforeDialog={() => handleTestAction(ActionType.REOPEN)}
        />
      )}
      {showSignByTimeStampButton && (
        <DetailActionButton
          promptKey="SIGN_PRIMARY_TIMESTAMP"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN"
              defaultMessage="Podpísanie časovou pečiatkou"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          apiCall={signByTimestampApiCall}
          onBeforeDialog={() => handleTestAction(ActionType.SIGN)}
        />
      )}
      {showSignByKepButton && (
        <DetailActionButton
          promptKey="SIGN_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN"
              defaultMessage="Podpísanie KEP"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať hlavný denník? Budete presmerovaný na podpisovací portál."
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__SIGN_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          submitCallback={signByKepCallback}
          onBeforeDialog={() => handleTestAction(ActionType.SIGN)}
        />
      )}
      {showCloseButton && (
        <DetailActionButton
          promptKey="CLOSE_PRIMARY"
          buttonLabel={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE"
              defaultMessage="Uzavrieť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE_DIALOG_TEXT"
              defaultMessage="Skutočne chcete uzavrieť hlavný denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PRIMARY_JOURNAL__ACTIONBAR__CLOSE_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={closeApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          successMessage="Uzatváranie zahájené. Záverečná správa sa generuje."
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.CLOSE
                ? Messages.PrimaryJournal.CLOSE[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
          onBeforeDialog={() => handleTestAction(ActionType.CLOSE)}
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey={`CANCEL_PARTIAL${isRequestCancel ? '_REQUEST' : ''}`}
          buttonLabel={
            <FormattedMessage
              id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__BUTTON"
              defaultMessage="Zrušiť denník"
            />
          }
          dialogText={
            isRequestCancel ? (
              <FormattedMessage
                id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL_NOTIFICATION__DIALOG_TEXT"
                defaultMessage="Odošle sa notifikácia administrátorovi, ktorý musí zrušenie potvrdiť. Uveďte dôvod na zrušenie."
              />
            ) : (
              <FormattedMessage
                id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TEXT"
                defaultMessage="Po potvrdení dôjde k zrušení denníku."
              />
            )
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PRIMARY_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={cancelApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
          isLocked={false}
          FormFields={() => (
            <FormTextArea name="reason" label="Dôvod" required />
          )}
          onSuccess={
            isRequestCancel
              ? async () => {
                  showSnackbar(
                    'Žiadosť o zrušenie bola odoslaná administrátorovi.',
                    SnackbarVariant.SUCCESS
                  );
                }
              : undefined
          }
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          onBeforeDialog={() => handleTestAction(ActionType.CANCEL)}
          /*  onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          } */
        />
      )}
    </>
  );
}
