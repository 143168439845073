/**
 * Dictionary - Mechanism permissions
 */
export enum PermissionMechanism {
  /**
   * Prehľad mechanizmov
   */
  MECHANISM_LIST = 'MECHANISM_LIST',

  /**
   * Detail mechanizmu
   */
  MECHANISM_DETAIL = 'MECHANISM_DETAIL',

  /**
   * Vytvorenie mechanizmu
   */
  MECHANISM_CREATE = 'MECHANISM_CREATE',

  /**
   * Editácia mechanizmu
   */
  MECHANISM_UPDATE = 'MECHANISM_UPDATE',

  /**
   * Zmazanie mechanizmu
   */
  MECHANISM_DELETE = 'MECHANISM_DELETE',

  /**
   * Zneplatnenie mechanizmu
   */
  MECHANISM_INVALIDATE = 'MECHANISM_INVALIDATE',

  /**
   * Obnovenie mechanizmu
   */
  MECHANISM_REVALIDATE = 'MECHANISM_REVALIDATE',

  /**
   * Prevzatie mechanizmov
   */
  MECHANISM_TAKEOVER = 'MECHANISM_TAKEOVER',

  /**
   * Import mechanismov
   */
  MECHANISM_IMPORT = 'MECHANISM_IMPORT',
}
