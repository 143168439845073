import { get } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormContext,
  FormTextField,
  PromptContext,
  useFormSelector,
  usePrompts,
} from '@eas/common-web';

export const useNameFreetext = (name: string) => {
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((v: any) => v);
  const person = get(values, name);

  /*     const test = [deps].map((d) => person?.[d]);

  const formInitialValues = [deps].reduce(
    (acc, attr) => ({ ...acc, [attr]: person?.[attr] || '' }),
    { freeteXT: true }
  ); */

  const key = `freetext_${name}_generic`;

  usePrompts(
    [
      {
        key,
        closeLabel: 'Prepnúť na číselník',
        confirmLabel: 'Potvrdiť freetext',
        dialogTitle: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TITLE"
            defaultMessage="Freetext"
          />
        ),
        dialogText: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TEXT"
            defaultMessage="Vyplňte formulár"
          />
        ),
        FormFields: () => {
          return (
            <>
              <FormTextField name="name" label="Názov" />
            </>
          );
        },
        formInitialValues: {
          name: person?.name,
        },
        dialogDisableBackdrop: true,
      },
    ],
    [person?.name]
  );

  const handleTestPrompt = useCallback(
    (e) => {
      e.stopPropagation();

      testPrompt({
        key,
        submitCallback: (v) => {
          setFieldValue(name, {
            freeText: true,
            ...v,
          });
        },
        closeCallback: () => {
          setFieldValue(name, null);
        },
      });
    },
    [key, testPrompt, setFieldValue, name]
  );

  const disabled = !!person?.freeText;

  return { handleTestPrompt, disabled };
};
