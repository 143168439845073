import { noop } from 'lodash';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  ContextHelp,
  DetailContext,
  DetailHandle,
  DetailMode,
  FormCheckboxGroup,
  FormContext,
  FormCustomField,
  FormPanel,
  ListSource,
  useFormSelector,
} from '@eas/common-web';
import { PermissionGroup, PermissionType, Role } from '@models';

const permissionLabelMapper = (perm: PermissionType) => (
  <div style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}>
    {perm.name}
    <ContextHelp type="HOVER" label={perm.description} />
  </div>
);

export function PermissionGroupFields({
  id,
  description,
  permissions,
}: PermissionGroup) {
  const intl = useIntl();

  const { mode } = useContext<DetailHandle<Role>>(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const userPermission =
    useFormSelector((data: Role) => data.permissions) ?? [];

  const permissionsSource: ListSource<PermissionType> = {
    items: permissions,
    loading: false,
    reset: noop,
    loadDetail: async (item) => item,
  };

  const groupPermissionCodes = permissions.map(
    (perm: PermissionType) => perm.id
  );

  const areAllSelected = groupPermissionCodes.every((perm) =>
    userPermission.includes(perm)
  );

  const handleAllPermissionsCheck = () => {
    if (areAllSelected) {
      setFieldValue(
        'permissions',
        userPermission.filter((perm) => !groupPermissionCodes.includes(perm))
      );
    } else {
      setFieldValue('permissions', [
        ...userPermission,
        ...groupPermissionCodes,
      ]);
    }
  };

  return (
    <React.Fragment key={id}>
      <FormPanel
        label={`${intl.formatMessage({
          id: 'ESD__ROLES__DETAIL__PERMISSIONS__TITLE',
          defaultMessage: 'Oprávnenia',
        })} - ${description}`}
        defaultExpanded={false}
        expandable
        summary={
          <span
            style={{
              fontFamily: 'montserrat',
              fontWeight: 600,
            }}
          >
            {`${
              groupPermissionCodes.filter((perm) =>
                userPermission.includes(perm)
              ).length
            } / ${permissions.length}`}
          </span>
        }
      >
        <FormCustomField
          label={intl.formatMessage({
            id: 'ESD__ROLES__DETAIL__PERMISSIONS__SELECT_ALL',
            defaultMessage: 'Označiť všetky oprávnenia',
          })}
        >
          <Checkbox
            value={areAllSelected}
            onChange={handleAllPermissionsCheck}
            disabled={mode === DetailMode.VIEW}
          />
        </FormCustomField>
        <FormCheckboxGroup<PermissionType>
          label={intl.formatMessage({
            id: 'ESD__ROLES__DETAIL__PERMISSIONS__LABEL',
            defaultMessage: 'Oprávnenia: ',
          })}
          name="permissions"
          source={permissionsSource}
          valueIsId={true}
          labelMapper={permissionLabelMapper}
        />
      </FormPanel>
    </React.Fragment>
  );
}
