import * as Yup from 'yup';
import { Mechanism } from '@models';

export function useValidationSchema() {
  return Yup.object<Mechanism>().shape({
    licencePlate: Yup.string().nullable().required(),
    category: Yup.mixed<Mechanism['category']>().required(),
    name: Yup.string().nullable().required(),
    subSupplier: Yup.string().nullable().required(),
  });
}
