import * as Yup from 'yup';
import { WorkDone } from '@models';

export function useValidationSchema() {
  return Yup.object<WorkDone>().shape({
    type: Yup.object<WorkDone>()
      .shape({
        category: Yup.mixed().nullable().required(),
        units: Yup.string().nullable().required(),
      })
      .nullable()
      .required(),
  });
}
