import * as Yup from 'yup';
import { Timesheet } from '@models';

export function useCreateValidationSchema() {
  return Yup.object<Timesheet>().shape({
    people: Yup.mixed().nullable().required(),
    hours: Yup.string().nullable().required(),
    overtime: Yup.string().nullable().required(),
    absence: Yup.object()
      .shape({
        type: Yup.string().nullable(),
        hours: Yup.string()
          .nullable()
          .when('type', {
            is: (type) => type != null,
            then: Yup.string().required().nullable(),
            otherwise: Yup.string().nullable(),
          }),
      })
      .nullable(),
  });
}
export function useUpdateValidationSchema() {
  return Yup.object<Timesheet>().shape({
    person: Yup.mixed().nullable().required(),
    hours: Yup.string().nullable().required(),
    overtime: Yup.string().nullable().required(),
    absence: Yup.object()
      .shape({
        type: Yup.string().nullable(),
        hours: Yup.string()
          .nullable()
          .when('type', {
            is: (type) => type != null,
            then: Yup.string().required().nullable(),
            otherwise: Yup.string().nullable(),
          }),
      })
      .nullable(),
  });
}
