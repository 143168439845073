import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  Checkbox,
  DetailContext,
  DetailHandle,
  DetailMode,
  FormAutocomplete,
  FormCustomField,
  FormPanel,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { Redirect } from '@components/redirect/redirect';
import { PersonType } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const {
    source: { data },
    mode,
    isExisting,
  } = useContext<DetailHandle<PersonType>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const tenant = useFormSelector((value: PersonType) => value.tenant);
  const userId = data?.user?.id;

  const professionSource = useProfessions();
  const suppliers = useSuppliers();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné údaje"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Meno"
            />
          }
          required
        />
        <FormTextField
          name="surname"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__SURNAME"
              defaultMessage="Priezvisko"
            />
          }
          required
        />
        <FormTextField
          name="email"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__EMAIL"
              defaultMessage="E-mail"
            />
          }
        />
        {tenant?.code === 'ZSD' && (
          <FormTextField
            name="kid"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__KID"
                defaultMessage="KID"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
        {tenant?.code === 'VSD' && (
          <FormTextField
            name="personalNumber"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__PERSONAL_NUMBER"
                defaultMessage="Osobné číslo"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
        {isExisting && (
          <FormCustomField
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__USER"
                defaultMessage="Používatel"
              />
            }
          >
            <Checkbox value={!!userId} onChange={noop} disabled />
            {hasPermission(Permission.User.USER_DETAIL) && userId && (
              <span
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <span style={{ height: 22 }}>
                  <Redirect url={EvidenceBrowserUrl.USERS} id={userId} />
                </span>
              </span>
            )}
          </FormCustomField>
        )}
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__WORK_INFO"
            defaultMessage="Pracovné údaje"
          />
        }
      >
        <FormAutocomplete
          name="profession"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__PROFESSION"
              defaultMessage="Pracovná pozícia"
            />
          }
          source={professionSource}
        />
        <FormAutocomplete
          name="supplier"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__SUPPLIER"
              defaultMessage="Dodávateľ"
            />
          }
          source={suppliers}
          disabled
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
