import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  AutocompleteSource,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  Tooltip,
} from '@eas/common-web';
import { personLabelMapper } from '@utils/label-mapper';
import { usePersonFreetext } from './use-person-freetext';

const FreeTextButton = ({
  handleTestPrompt,
  visible,
}: {
  handleTestPrompt: React.MouseEventHandler<HTMLButtonElement>;
  visible?: boolean;
}) => {
  const { mode } = useContext(DetailContext);

  if (!visible && mode !== DetailMode.EDIT && mode !== DetailMode.NEW)
    return null;

  return (
    <Tooltip title="Zadať ako freetext">
      <IconButton
        onClick={handleTestPrompt}
        style={{
          marginRight: '2pt',
          padding: 1,
        }}
      >
        <AutorenewIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const PersonFreetextField = ({
  autocompleteSource,
  name,
  label,
  visible,
}: {
  autocompleteSource: AutocompleteSource<any>;
  name: string;
  label: React.ReactNode;
  visible?: boolean;
}) => {
  const { handleTestPrompt, disabled } = usePersonFreetext(name);

  return (
    <FormAutocomplete
      name={name}
      source={autocompleteSource}
      labelMapper={personLabelMapper}
      label={label}
      required
      disabled={disabled}
      after={
        <FreeTextButton handleTestPrompt={handleTestPrompt} visible={visible} />
      }
    />
  );
};

export default PersonFreetextField;
