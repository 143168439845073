import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  FormCheckbox,
  FormInstantField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { DocumentsField } from '@composite/documents-collection/documents-field';
import { PersonField2 } from '@composite/person-collection2/person-field2';
import { useSignatureTypes } from '@composite/signatures-field/signatures-api';
import PersonFreetextField from '@components/person-freetext-field/person-freetext-field';
import { Redirect } from '@components/redirect/redirect';
import { Bozp } from '@models';
import { EvidenceBrowserUrl, JournalEntryState } from '@enums';
import { FILES, INSTRUCTED_PERSON } from './bozp-hooked-sources';

export const Fields = () => {
  const {
    source: { data },
  } = useContext<DetailHandle<Bozp>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  /*   const { hasPermission } = useContext<UserContext<Me>>(UserContext); */

  const instructedPersonSource = hookedSources[INSTRUCTED_PERSON]?.source;
  const filesSource = hookedSources[FILES]?.source;

  const persons = usePersons();
  const signatureType = useSignatureTypes();

  const journalEntryId = data?.journalEntry?.id;

  const isSigned = data?.signed;
  const isJournalEntryClosed =
    data?.journalEntry?.state !== JournalEntryState.OPENED;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__BOZP__DETAIL__PANEL__BOZP_GENERAL"
            defaultMessage="Denný záznam"
          />
        }
      >
        <FormInstantField
          name="journalEntry.validityDate"
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__HEADER"
              defaultMessage="Dátum denného záznamu"
            />
          }
          representation="local-date"
          after={
            journalEntryId && (
              <Redirect
                url={EvidenceBrowserUrl.JOURNAL_ENTRY}
                id={journalEntryId}
              />
            )
          }
          disabled
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__PANEL__BOZP_GENERAL"
            defaultMessage="Základné"
          />
        }
      >
        <FormCheckbox
          name="signed"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="BOZP podpísané"
            />
          }
          disabled
        />

        <FormTextField
          name="header"
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__HEADER"
              defaultMessage="Hlavička"
            />
          }
          required
        />
        <FormTextArea
          name="awareness"
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__HEADER"
              defaultMessage="Oboznámenie"
            />
          }
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__BOZP__DETAIL__PANEL__BOZP_PERSONS"
            defaultMessage="Osoba vykonávajúca poučenie"
          />
        }
      >
        <PersonFreetextField
          name="briefedBy.person"
          autocompleteSource={persons}
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__BRIEFED_BY"
              defaultMessage="Osoba vykonávajúca poučenie"
            />
          }
          /*  notifyChange={(v) => {
            if (!v) setFieldValue('briefedBy', null);
          }} */
        />
        <FormCheckbox
          name="briefedBy.signature.id"
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__BRIEFED_BY"
              defaultMessage="Podpísané osobou vykonávajúcou poučenie"
            />
          }
          disabled
        />
        <FormSelect
          name="briefedBy.signature.type"
          source={signatureType}
          valueIsId
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__BRIEFED_BY"
              defaultMessage="Typ podpisu"
            />
          }
          disabled
        />
        <FormInstantField
          name="briefedBy.signature.created"
          label={
            <FormattedMessage
              id="ESD__BOZP__DETAIL__FIELD_LABEL__BRIEFED_BY"
              defaultMessage="Dátum a čas podpisu"
            />
          }
          disabled
        />
      </FormPanel>

      <PersonField2
        evidence="BOZP"
        source={instructedPersonSource}
        exceptions={regionApiErrors.BOZP_PERSON}
        disabled={isJournalEntryClosed}
      />

      <DocumentsField
        evidence="BOZP"
        source={filesSource}
        disabled={isSigned || isJournalEntryClosed}
      />
    </>
  );
};
