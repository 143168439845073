/**
 * Supplier permissions
 */
export enum PermissionSupplier {
  /**
   * Prehľad dodávatelov
   */
  SUPPLIER_LIST = 'SUPPLIER_LIST',

  /**
   * Detail dodávateľa
   */
  SUPPLIER_DETAIL = 'SUPPLIER_DETAIL',

  /**
   * Vytvorenie dodávateľa
   */
  SUPPLIER_CREATE = 'SUPPLIER_CREATE',

  /**
   * Editácia dodávateľa
   */
  SUPPLIER_UPDATE = 'SUPPLIER_UPDATE',

  /**
   * Zmazanie dodávateľa
   */
  SUPPLIER_DELETE = 'SUPPLIER_DELETE',

  /**
   * Zneplatnenie dodávateľa
   */
  SUPPLIER_INVALIDATE = 'SUPPLIER_INVALIDATE',

  /**
   * Obnovenie dodávateľa
   */
  SUPPLIER_REVALIDATE = 'SUPPLIER_REVALIDATE',
}
