import { useContext } from 'react';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import {
  DetailContext,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
} from '@eas/common-web';
import { ActionType, EvidenceApiUrl, JournalTypeEnum } from '@enums';

interface ActionError {
  code: string;
  data: number;
}

interface ActionErrorResponse {
  valid: boolean;
  errors: ActionError[];
}

const JournalApiUrls = {
  [JournalTypeEnum.PRIMARY]: EvidenceApiUrl.PRIMARY_JOURNAL,
  [JournalTypeEnum.PARTIAL]: EvidenceApiUrl.PARTIAL_JOURNAL,
  [JournalTypeEnum.ENTRY]: EvidenceApiUrl.JOURNAL_ENTRY,
} as const;

const ActionUrls = {
  [ActionType.CLOSE]: '/can-close',
  [ActionType.SIGN]: '/can-sign',
  [ActionType.CANCEL]: '/can-cancel',
  [ActionType.REOPEN]: '/can-reopen',
  [ActionType.DELETE]: '/can-delete',
} as const;

const getMessagePrefix = (type: ActionType): string => {
  const prefixMap: Record<ActionType, string> = {
    [ActionType.CLOSE]: 'Chyba zatvorenia: ',
    [ActionType.SIGN]: 'Chyba podpísania: ',
    [ActionType.CANCEL]: 'Chyba zrušenia: ',
    [ActionType.REOPEN]: 'Chyba znovuotvorenia: ',
    [ActionType.DELETE]: 'Chyba zmazania: ',
  };

  return prefixMap[type] || 'niečo sa pokazilo';
};

const handleErrors = (
  error: ActionError,
  type: ActionType,
  journal: JournalTypeEnum
) => {
  const prefix = getMessagePrefix(type);
  //TODO: BE now sends wrong counts sometimes - when fixed add it back to return statement string
  const count = error.data;

  const journalErrors = regionApiErrors[journal] || {};
  const message = journalErrors[error.code as keyof typeof journalErrors];

  if (message) {
    return `${prefix}${message}`;
  }
  return `${prefix} Neznáma chyba. Kontaktujte administrátora.`;
};

export const useTestAction = (journal: JournalTypeEnum) => {
  const { showSnackbar } = useContext(SnackbarContext);
  const {
    source: { data },
  } = useContext(DetailContext);

  const handleTestAction = async (type: ActionType) => {
    try {
      const res: ActionErrorResponse = await abortableFetch(
        `${JournalApiUrls[journal]}/${data?.id}${ActionUrls[type]}`
      ).json();

      if (res.valid) {
        return true;
      } else {
        // this map wont work as only one snackbar is used.
        res.errors.map((e) => {
          showSnackbar(
            handleErrors(e, type, journal),
            SnackbarVariant.WARNING,
            true
          );
        });

        return false;
      }
    } catch (error) {
      // show default snackbar error
      const prefix = getMessagePrefix(type);
      showSnackbar(
        `${prefix} Neznáma chyba. Kontaktujte administrátora.`,
        SnackbarVariant.WARNING,
        true
      );
      return false;
    }
  };
  return { handleTestAction };
};
