export const regionApiErrors = {
  PRIMARY: {
    ENTITY_ALREADY_EXIST: 'Hlavný denník s rovnakým názvom už existuje',
    SOME_JOURNAL_ENTRIES_EXIST: 'K hlavnému denníku existujú denné záznamy',
    SOME_PARTIAL_JOURNALS_EXIST: 'K hlavnému denníku existujú dielčie denníky',
    SOME_PARTIAL_JOURNALS_ARE_OPEN:
      'Nie všetky dielčie denníky tohto denníka sú uzatvorené',
    SOME_PARTIAL_JOURNALS_ARE_NOT_SIGNED:
      'Nie všetky dielčí denníky tohto denníka sú podpísané.',
    SOME_PARTIAL_JOURNALS_ARE_SIGNED:
      'Niektoré dielčí denníky tohto denníka sú podpísané.',
    NOT_ALL_ENTRIES_ARE_SIGNED:
      'Nie všetky denné záznamy tohto denníka sú podpísané',
  },
  PARTIAL: {
    ENTITY_ALREADY_EXIST: 'Dielčí denník s rovnakým názvom už existuje',
    NOT_ALL_ENTRIES_ARE_SIGNED:
      'Nie všetky denné záznamy tohto denníka sú podpísané.',
    SOME_JOURNAL_ENTRIES_EXIST: 'K dielčiemu denníku existujú denné záznamy.',
    CANNOT_SIGN_PARTIAL_JOURNAL: 'Líniový hlavný denník nie je uzavretý.',
  },
  ENTRY: {
    ENTITY_ALREADY_EXIST: 'Dielčí denník s rovnakým názvom už existuje',
  },
  MECHANISMS: {
    ENTITY_ALREADY_EXIST: 'Mechanizmus s rovnakou špz už existuje',
  },
  MECHANISM_CATEGORY: {
    ENTITY_ALREADY_EXIST: 'Kategória mechanizmu s rovnakým názvom už existuje',
    DICTIONARY_OBJECT_HAS_REFERENCE:
      'Existuje referencia v číselníku mechanizmov.',
  },
  WORK: {
    ENTITY_ALREADY_EXIST: 'Práca s rovnakým názvom už existuje',
  },
  WORK_CATEGORY: {
    ENTITY_ALREADY_EXIST: 'Kategória práce s rovnakým názvom už existuje',
    DICTIONARY_OBJECT_HAS_REFERENCE: 'Existuje referencia v číselníku prác.',
  },
  WORK_TBPP: {
    ENTITY_ALREADY_EXIST: 'TBPP s rovnakým názvom už existuje',
    DICTIONARY_OBJECT_HAS_REFERENCE: 'Existuje referencia v číselníku prác.',
  },
  PROFESSION: {
    ENTITY_ALREADY_EXIST: 'Pracovná pozícia s rovnakým názvom už existuje',
  },
  ABSENCE: {
    ENTITY_ALREADY_EXIST: 'Neprítomnosť s rovnakým názvom už existuje',
  },
  BOZP_PERSON: {
    PERSON_TO_SIGN_IS_SIGNED: 'Podpísanou osobu nemožno odstrániť.',
  },
  OTHER_RECORDS_PERSON: {
    PERSON_TO_SIGN_IS_SIGNED: 'Podpísanou osobu nemožno odstrániť.',
    ENDPOINT_ACCESS_DENIED: 'Prístup bol zamítnutý.',
  },
} as const;
