import React from 'react';
import { FileRef, FormFileField, useFormSelector } from '@eas/common-web';
import { ACCEPTED_DOCUMENTS_FORMATS } from '@composite/attachments-collection/dialogs/attachments-create-dialog';
import { FormImageField } from '@components/form/image-field/form-image-field';

export function DocumentsDialog() {
  const values = useFormSelector((v: { file: FileRef }) => v);

  const isImageDocument = values?.file?.contentType?.includes('image');

  return (
    <>
      <FormFileField
        name="file"
        label="Dokument"
        accept={ACCEPTED_DOCUMENTS_FORMATS}
      />
      {isImageDocument && (
        <FormImageField
          name="file"
          showFileField={false}
          //   label="Obrázok"
          height={400}
        />
      )}
    </>
  );
}
