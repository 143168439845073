import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionType } from 'src/enums/action-type';
import * as Yup from 'yup';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  FormTextArea,
  FormTextField,
  NavigationContext,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { useSignJournalByKepDialog } from '@components/kep-signature/sign-partial-journal-kep-hook';
import { ErrorObject, PartialJournal } from '@models';
import { EvidenceBrowserUrl, JournalTypeEnum, Messages } from '@enums';
import { useTestAction } from '@utils/use-test-action';
import { useCancelPartialJournalDialog } from './dialog-actions/cancel-partial-journal-hook';
import { useClosePartialJournalDialog } from './dialog-actions/close-partial-journal-hook';
import { useReopenPartialJournalDialog } from './dialog-actions/reopen-partial-journal-hook';
import { useSignPartialJournalByTimestamp } from './dialog-actions/sign-partial-journal-timestamp-hook';

export function PartialJournalActionbar() {
  const {
    source: { data },
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleTestAction } = useTestAction(JournalTypeEnum.PARTIAL);

  const { navigate } = useContext(NavigationContext);

  const { callApi: closeApiCall, showButton: showCloseButton } =
    useClosePartialJournalDialog();

  const {
    callApi: signByTimestapApiCall,
    showButton: showSignByTimestampButton,
  } = useSignPartialJournalByTimestamp();

  const { callApi: reopenApiCall, showButton: showReopenButton } =
    useReopenPartialJournalDialog();

  const { callApi: signByKepCallback, showButton: showSignByKepButton } =
    useSignJournalByKepDialog(JournalTypeEnum.PARTIAL);

  const {
    callApi: cancelApiCall,
    showButton: showCancelButton,
    isRequestCancel,
  } = useCancelPartialJournalDialog();

  return (
    <>
      {data?.parent?.id && (
        <TertiaryDetailActionbarButton
          label="Späť na hlavný denník"
          isLocked={false}
          onClick={() =>
            navigate(`${EvidenceBrowserUrl.PRIMARY_JOURNAL}/${data.parent?.id}`)
          }
        />
      )}

      {showReopenButton && (
        <DetailActionButton
          promptKey="REOPEN_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__BUTTON"
              defaultMessage="Otvoriť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete znovu otvoriť dielčí denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__REOPEN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={reopenApiCall}
          ButtonComponent={TertiaryDetailActionbarButton}
          FormFields={() => (
            <FormTextField name="reason" label="Dôvod" required />
          )}
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          isLocked={false}
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
          onBeforeDialog={() => handleTestAction(ActionType.REOPEN)}
        />
      )}
      {showSignByTimestampButton && (
        <DetailActionButton
          promptKey="SIGN_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__BUTTON"
              defaultMessage="Podpísanie časovou pečiatkou"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať dielčí denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__SIGN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={signByTimestapApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          onBeforeDialog={() => handleTestAction(ActionType.SIGN)}
        />
      )}
      {showSignByKepButton && (
        <DetailActionButton
          promptKey="SIGN_PARTIAL_KEP"
          buttonLabel={
            <FormattedMessage
              id="ESD__PARTIAL_JOURNAL__ACTIONBAR__SIGN_KEP"
              defaultMessage="Podpísanie KEP"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD__PARTIAL_JOURNAL__ACTIONBAR__SIGN_DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať dielčí denník? Budete presmerovaný na podpisovací portál."
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD__PARTIAL_JOURNAL__ACTIONBAR__SIGN_DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          submitCallback={signByKepCallback}
          onBeforeDialog={() => handleTestAction(ActionType.SIGN)}
        />
      )}
      {showCloseButton && (
        <DetailActionButton
          promptKey="CLOSE_PARTIAL"
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__BUTTON"
              defaultMessage="Uzavrieť denník"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__DIALOG_TEXT"
              defaultMessage="Skutočne chcete uzavrieť dielčí denník?"
            />
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CLOSE__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={closeApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          successMessage="Uzatváranie zahájené. Záverečná správa sa generuje."
          onError={async (err: ErrorObject<string>) => {
            if (err.code && err.code in Messages.PartialJournal.CLOSE) {
              showSnackbar(...Messages.PartialJournal.CLOSE[err.code]);
            } else {
              showSnackbar(...Messages.Common.UNEXPECTED_ERROR);
            }
          }}
          onBeforeDialog={() => handleTestAction(ActionType.CLOSE)}
        />
      )}
      {showCancelButton && (
        <DetailActionButton
          promptKey={`CANCEL_PARTIAL${isRequestCancel ? '_REQUEST' : ''}`}
          buttonLabel={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__BUTTON"
              defaultMessage="Zrušiť denník"
            />
          }
          dialogText={
            isRequestCancel ? (
              <FormattedMessage
                id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL_NOTIFICATION__DIALOG_TEXT"
                defaultMessage="Odošle sa notifikácia administrátorovi, ktorý musí zrušenie potvrdiť. Uveďte dôvod na zrušenie."
              />
            ) : (
              <FormattedMessage
                id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TEXT"
                defaultMessage="Po potvrdení dôjde k zrušení denníku."
              />
            )
          }
          dialogTitle={
            <FormattedMessage
              id="ESD_PARTIAL_JOURNAL__ACTION_BAR__CANCEL__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          apiCall={cancelApiCall}
          ButtonComponent={PrimaryDetailActionbarButton}
          isLocked={false}
          FormFields={() => (
            <FormTextArea name="reason" label="Dôvod" required />
          )}
          formValidationSchema={Yup.object().shape({
            reason: Yup.string().nullable().required(),
          })}
          onSuccess={
            isRequestCancel
              ? async () => {
                  showSnackbar(
                    'Žiadosť o zrušenie bola úspešně odoslaná.',
                    SnackbarVariant.SUCCESS
                  );
                }
              : undefined
          }
          onBeforeDialog={() => handleTestAction(ActionType.CANCEL)}
          /*  onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.PrimaryJournal.REOPEN
                ? Messages.PrimaryJournal.REOPEN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          } */
        />
      )}
    </>
  );
}
