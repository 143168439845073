import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  Tabs,
  TabsConfig,
  UserContext,
} from '@eas/common-web';
import { Me, Supplier } from '@models';
import { OverviewFields } from './fields/overview';

export function Fields() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<Supplier>>(DetailContext);

  //const isCorrectSupplier = data && data?.id === user?.supplier?.id;

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__SUPPLIER__DETAIL__PANEL_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'OVERVIEW',
      validationKeys: [],
      content: <OverviewFields />,
    },
  ];
  return <Tabs config={config} />;
}
