import * as Yup from 'yup';
import { JournalEntry, Mechanism, MechanismCategory } from '@models';

export function useValidationSchema() {
  return Yup.object<Mechanism>().shape({
    hours: Yup.string().required().nullable(),
    distance: Yup.string().required().nullable(),
    /*   type: Yup.object()
      .shape({
        category: Yup.object()
          .shape({
            name: Yup.string().nullable().required(),
          })
          .nullable()
          .required(),

        name: Yup.string().nullable().required(),
        licencePlate: Yup.string().nullable().required(),
        subSupplier: Yup.string().nullable().required(),
      })
      .nullable(), */
  });
}
