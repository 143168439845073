import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableFilterCells,
  UserContext,
} from '@eas/common-web';
import { useWorkCategories } from '@modules/dict__work-category/work-category-api';
import { useWorkTbpps } from '@modules/dict__work-tbpp/work-tbpp-api';
import { Me, Work } from '@models';

export const useColumns = () => {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const intl = useIntl();

  const showTBPP = user?.tenant?.code !== 'ZSD';

  const {
    columnOrder,
    columnCreated,
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  } = DictionaryEvidence.useDictionaryColumns<Work>();

  return [
    columnOrder,
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__NAME',
        defaultMessage: 'Práca',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'category',
      sortkey: 'category.name',
      filterkey: 'category.id',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__CATEGORY',
        defaultMessage: 'Kategória práce',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useWorkCategories),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'units',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__UNITS',
        defaultMessage: 'Jednotky práce',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'tbpp',
      sortkey: 'tbpp.name',
      filterkey: 'tbpp.id',
      name: intl.formatMessage({
        id: 'ESD__WORK__TABLE__COLUMN__TBPP',
        defaultMessage: 'TBPP',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useWorkTbpps),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
      visible: showTBPP,
    },
    columnCreated,
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  ];
};
