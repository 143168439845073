import { get } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  FormContext,
  FormTextField,
  PromptContext,
  useFormSelector,
  usePrompts,
} from '@eas/common-web';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import NameFreetextField from '@components/name-freetext-field/name-freetext-field';

export const usePersonFreetext = (name: string) => {
  const { testPrompt } = useContext(PromptContext);
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((v: any) => v);
  const person = get(values, name);

  /*   const test = [deps].map((d) => person?.[d]); */

  /*   const formInitialValues = [deps].reduce(
    (acc, attr) => ({ ...acc, [attr]: person?.[attr] || '' }),
    { freeteXT: true }
  ); */

  const key = `freetext_${name}`;

  usePrompts(
    [
      {
        key,
        closeLabel: 'Prepnúť na číselník',
        confirmLabel: 'Potvrdiť freetext',
        dialogWidth: 500,
        dialogTitle: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TITLE"
            defaultMessage="Freetext"
          />
        ),
        dialogText: (
          <FormattedMessage
            id="ESD__FREETEXT__DIALOG_CONFIRM__TEXT"
            defaultMessage="Vyplňte formulár"
          />
        ),
        FormFields: () => {
          return (
            <>
              <FormTextField name="name" label="Meno" required />
              <FormTextField name="surname" label="Priezvisko" required />
              <NameFreetextField
                name="profession"
                label="Profesia"
                autocompleteSource={useProfessions()}
              />
              <NameFreetextField
                name="supplier"
                label="Dodávateľ"
                autocompleteSource={useSuppliers()}
              />
            </>
          );
        },
        formValidationSchema: Yup.object().shape({
          name: Yup.string().nullable().required(),
          surname: Yup.string().nullable().required(),
        }),
        formInitialValues: {
          name: person?.name,
          surname: person?.surname,
          profession: person?.profession,
          supplier: person?.supplier,
        },
        dialogDisableBackdrop: true,
      },
    ],
    [
      person?.name,
      person?.surname,
      person?.profession?.name,
      person?.supplier?.name,
    ]
  );

  const handleTestPrompt = useCallback(
    (e) => {
      e.stopPropagation();

      testPrompt({
        key,
        submitCallback: (v) => {
          setFieldValue(name, {
            freeText: true,
            ...v,
          });
        },
        closeCallback: () => {
          setFieldValue(name, null);
        },
      });
    },
    [key, testPrompt, setFieldValue, name]
  );

  const disabled = !!person?.freeText;

  return { handleTestPrompt, disabled };
};
