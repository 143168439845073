import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { PrimaryJournal } from '@models';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';
import { useIsAdmin } from '@utils/use-is-admin';
import { useUserAssignments } from '@utils/use-user-assignments';

export function useCancelPrimaryJournalDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);

  const { hasPermission } = useContext(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();

  const isAdmin = useIsAdmin();

  const isRequestCancel =
    data?.state === PrimaryJournalState.CLOSED && !isAdmin;

  const callApi = (journalId: string, formValues: { reason: string }) => {
    return abortableFetch(
      `${EvidenceApiUrl.PRIMARY_JOURNAL}/${journalId}/cancel${
        isRequestCancel ? '/request' : ''
      }`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: formValues?.reason,
      }
    );
  };

  const showButton =
    data?.state !== PrimaryJournalState.CANCELLED &&
    data?.state !== PrimaryJournalState.SIGNED &&
    (hasPermission(Permission.PrimaryJournal.PRIMARY_JOURNAL_CANCEL) ||
      hasPermission(
        Permission.PrimaryJournal.PRIMARY_JOURNAL_CANCEL_REQUEST
      )) &&
    isPrimaryJournalAssigned(data?.id);

  return { callApi, showButton, isRequestCancel };
}
