import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { AutocompleteSource, FormAutocomplete, Tooltip } from '@eas/common-web';
import { personLabelMapper } from '@utils/label-mapper';
import { useNameFreetext } from './use-name-freetext';

const FreeTextButton = ({
  handleTestPrompt,
}: {
  handleTestPrompt: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  /*   const { mode } = useContext(DetailContext);

  if (mode !== DetailMode.EDIT && mode !== DetailMode.NEW) return null; */

  return (
    <Tooltip title="Zadať ako freetext">
      <IconButton
        onClick={handleTestPrompt}
        style={{
          marginRight: '2pt',
          padding: 1,
        }}
      >
        <AutorenewIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const NameFreetextField = ({
  autocompleteSource,
  name,
  label,
}: {
  autocompleteSource: AutocompleteSource<any>;
  name: string;
  label: React.ReactNode;
}) => {
  const { handleTestPrompt, disabled } = useNameFreetext(name);

  return (
    <FormAutocomplete
      name={name}
      source={autocompleteSource}
      labelMapper={personLabelMapper}
      label={label}
      disabled={disabled}
      after={<FreeTextButton handleTestPrompt={handleTestPrompt} />}
    />
  );
};

export default NameFreetextField;
