import { PartialJournalState } from 'src/enums/partial-journal-state';
import { PartialJournalType } from 'src/enums/partial-journal-type';
import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';

export function usePartialJournalStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: PartialJournalState.CREATED, name: 'Vytvorený' },
    { id: PartialJournalState.OPENED, name: 'Otvorený' },
    { id: PartialJournalState.CLOSED, name: 'Uzavretý' },
    { id: PartialJournalState.CLOSING, name: 'Uzatváranie' },
    { id: PartialJournalState.SIGNED, name: 'Podpísaný' },
    { id: PartialJournalState.CANCELLED, name: 'Zrušený' },
    { id: PartialJournalState.REOPENED, name: 'Znovuotvorený' },
  ]);
}

export function usePartialJournalTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: PartialJournalType.EP, name: 'Elektropráce' },
    { id: PartialJournalType.ZP, name: 'Zemné práce' },
    { id: PartialJournalType.OTHER, name: 'Ostatné' },
  ]);
}
