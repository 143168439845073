import { subWeeks } from 'date-fns';
import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  FormCustomField,
  FormLocalDateField,
  FormPanel,
  Select,
  TextField,
} from '@eas/common-web';
import { useJournalTypes } from '@modules/journal-entry/journal-entry-api';
import { userLabelMapper } from '@utils/label-mapper';

export const getAvailableValidityDates = () => {
  const today = new Date().setHours(0, 0, 0, 0);

  const minDate = subWeeks(today, 1).toISOString();

  const maxDate = new Date().toISOString();

  return { minDate, maxDate };
};

export function EntryCreateDialog() {
  const { source } =
    useContext<DetailHandle</* PrimaryJournal | PartialJournal */ any>>(
      DetailContext
    );

  const journalTypes = useJournalTypes();
  const journalSource = source.data;

  const { minDate, maxDate } = getAvailableValidityDates();
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__COLLECTION__PANEL_JOURNAL"
            defaultMessage="Denník"
          />
        }
      >
        <FormCustomField
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__COLLECTION__FIELD_LABEL__NAME"
              defaultMessage="Názov stavby"
            />
          }
        >
          <TextField
            value={
              journalSource?.construction?.name ??
              journalSource?.parent?.construction?.name ??
              ''
            }
            disabled
          />
        </FormCustomField>

        <FormCustomField
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Typ denníka"
            />
          }
        >
          <Select
            source={journalTypes}
            value={journalSource?.journalType}
            onChange={noop}
            valueIsId
            disabled
          />
        </FormCustomField>
        <FormCustomField
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__COLLECTION__FIELD_LABEL__JOURNAL_LEADER"
              defaultMessage="Hlavný stavbyvedúci / Stavbyvedúci dodávateľa"
            />
          }
        >
          <TextField value={userLabelMapper(journalSource?.leader)} disabled />
        </FormCustomField>
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormLocalDateField
          name="validityDate"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__COLLECTION__FIELD_LABEL__VALIDITY_DATE"
              defaultMessage="Dátum denného záznamu"
            />
          }
          representation="instant"
          required
          maxDate={maxDate}
          minDate={minDate}
        />
      </FormPanel>
    </>
  );
}
