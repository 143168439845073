import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormPanel, FormTextField } from '@eas/common-web';

export function OtherRecordsCreateDialog() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__OTHER_RECORDS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormTextField
          name="institutionName"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__COLLECTION__FIELD_LABEL__INSTITUTION_NAME"
              defaultMessage="Názov inštitúcie na stavbe"
            />
          }
          required
        />
        <FormTextField
          name="header"
          label={
            <FormattedMessage
              id="ESD__OTHER_RECORDS__COLLECTION__FIELD_LABEL__HEADER"
              defaultMessage="Hlavička"
            />
          }
          required
        />
      </FormPanel>
    </>
  );
}
