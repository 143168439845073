/**
 * Dictionary - Mechanism category permissions
 */
export enum PermissionMechanismCategory {
  /**
   * Prehľad kategórií mechanizmov
   */
  MECHANISM_CATEGORY_LIST = 'MECHANISM_CATEGORY_LIST',

  /**
   * Detail kategórie mechanizmu
   */
  MECHANISM_CATEGORY_DETAIL = 'MECHANISM_CATEGORY_DETAIL',

  /**
   * Vytvorenie kategórie mechanizmu
   */
  MECHANISM_CATEGORY_CREATE = 'MECHANISM_CATEGORY_CREATE',

  /**
   * Editácia kategórie mechanizmu
   */
  MECHANISM_CATEGORY_UPDATE = 'MECHANISM_CATEGORY_UPDATE',

  /**
   * Zmazanie kategórie mechanizmu
   */
  MECHANISM_CATEGORY_DELETE = 'MECHANISM_CATEGORY_DELETE',

  /**
   * Zneplatnenie kategórie mechanizmu
   */
  MECHANISM_CATEGORY_INVALIDATE = 'MECHANISM_CATEGORY_INVALIDATE',

  /**
   * Obnovenie kategórie mechanizmu
   */
  MECHANISM_CATEGORY_REVALIDATE = 'MECHANISM_CATEGORY_REVALIDATE',

  /**
   * Prevzatie kategórie mechanizmov
   */
  MECHANISM_CATEGORY_TAKEOVER = 'MECHANISM_CATEGORY_TAKEOVER',

  /**
   * Import kategorie mechanismu
   */
  MECHANISM_CATEGORY_IMPORT = 'MECHANISM_CATEGORY_IMPORT',
}
