import React, { useContext } from 'react';
import { ApiFilterOperation, EvidenceContext } from '@eas/common-web';
import { INSTRUCTED_PERSON } from '@modules/bozp/bozp-hooked-sources';
import { usePersons } from '@modules/person/person-api';
import PersonFreetextField from '@components/person-freetext-field/person-freetext-field';

export function PersonAssignDialog() {
  // This hook returns all assigned people to a specific journal (primary or partial or entry).
  const { hookedSources } = useContext(EvidenceContext);

  const instructedPersons = hookedSources[INSTRUCTED_PERSON].source.items;

  const allInstructedPeopleIds = (instructedPersons || []).map(
    (p) => p?.personToSign?.person?.id
  );

  const persons = usePersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allInstructedPeopleIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <PersonFreetextField
        autocompleteSource={persons}
        name="person"
        label="Osoba"
        visible
      />
    </>
  );
}
