import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useAbsences } from '@modules/dict__absence/absence-api';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { usePersons } from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'person',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__PERSON',
        defaultMessage: 'Osoba',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePersons,
        labelMapper: personLabelMapper,
        multiple: false,
      }),
    },
    {
      datakey: 'person.profession',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__PROFESSION',
        defaultMessage: 'Pracovná pozícia',
      }),
      width: 150,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useProfessions,
        multiple: false,
      }),
    },
    {
      datakey: 'person.supplier.name',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN_SUPPLIER',
        defaultMessage: 'Dodávateľ',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'date',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__DATE',
        defaultMessage: 'Dátum',
      }),
      width: 100,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'hours',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__HOURS',
        defaultMessage: 'Odpracované hodiny',
      }),
      width: 150,
    },
    {
      datakey: 'overtime',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__OVERTIME',
        defaultMessage: 'Nadčas',
      }),
      width: 100,
    },
    {
      datakey: 'absence.type',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__ABSENCE_TYPE',
        defaultMessage: 'Neprítomnosť',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useAbsences,
        multiple: false,
      }),
    },
    {
      datakey: 'absence.hours',
      name: intl.formatMessage({
        id: 'ESD__TIMESHEET_COLLECTION__TABLE__COLUMN__OVERTIME',
        defaultMessage: 'Neprítomnosť - hodiny',
      }),
      width: 150,
    },
  ];
}
