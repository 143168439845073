import React, { useContext, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  AutocompleteProps,
  FormAutocomplete,
  FormContext,
  FormFieldProps,
  FormTextField,
  TextFieldProps,
  Tooltip,
} from '@eas/common-web';

interface FreeTextProps {
  isFreeText: boolean;
  setIsFreetext: React.Dispatch<React.SetStateAction<boolean>>;
  onSwitchToAutocomplete?: () => void;
  onSwitchToFreetext?: () => void;
  autocompleteProps: Omit<
    FormFieldProps<AutocompleteProps<any>>,
    'endAdornment'
  >;
  textFieldProps: Omit<FormFieldProps<TextFieldProps>, 'endAdornment'>;
  fieldsToReset?: string[];
}

export const FormFreeTextFieldParentState = ({
  isFreeText,
  setIsFreetext,
  onSwitchToAutocomplete = () => {},
  onSwitchToFreetext = () => {},
  autocompleteProps,
  textFieldProps,
  fieldsToReset,
}: FreeTextProps) => {
  const { setFieldValue } = useContext(FormContext);

  const resetField = (field: string) => {
    setFieldValue(field, null);
  };

  // BE needs to get "freeText: boolean" attribute to know if user user entered the input manually.
  // It is called in effect, because we can have multiple freetext comoponents and all of them are switched together.
  const setIsFreeTextAttribute = (val: boolean) => {
    setFieldValue(`${autocompleteProps.name}.freeText`, val);
  };

  useEffect(() => {
    if (isFreeText) {
      setIsFreeTextAttribute(true);
      setFieldValue(`${autocompleteProps.name}.id`, undefined);
    }
  }, [isFreeText]);

  return (
    <>
      {isFreeText ? (
        <FormTextField
          {...textFieldProps}
          endAdornment={
            <Tooltip title="Prepnúť na výber z číselníka">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  onSwitchToAutocomplete();

                  if (fieldsToReset) {
                    fieldsToReset.forEach((f) => resetField(f));
                  }

                  setIsFreetext(false);
                }}
                style={{
                  marginRight: '2pt',
                  padding: 1,
                }}
              >
                <AutorenewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
        />
      ) : (
        <FormAutocomplete
          {...autocompleteProps}
          endAdornment={
            <Tooltip title="Prepnúť na manuálne zadanie">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  onSwitchToFreetext();

                  setIsFreetext(true);
                }}
                style={{
                  marginRight: '2pt',
                  padding: 1,
                }}
              >
                <AutorenewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
        />
      )}
    </>
  );
};
