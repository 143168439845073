import { useIntl } from 'react-intl';
import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { OtherRecord } from '@models';

export const useColumns = (): TableColumn<OtherRecord>[] => {
  const intl = useIntl();
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<OtherRecord>();

  return [
    {
      datakey: 'instututionName',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__TABLE__COLUMN__INSTITUTION_NAME',
        defaultMessage: 'Názov inštitúcie na stavbe',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
    },
    {
      datakey: 'description',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__TABLE__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
    },
    ...authoredColumns,
  ];
};
