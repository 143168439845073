import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { TakeoverTableToolbarButton } from '@components/takeover-table-toolbar-button/takeover-table-toolbar-button';
import { Me, TBPP } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './work-tbpp-columns';
import { Fields } from './work-tbpp-fields';
import { useValidationSchema } from './work-tbpp-schema';

export const WorkTbpps = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.TBPP.TBPP_CREATE],
      EDIT: [Permission.TBPP.TBPP_UPDATE],
      REMOVE: [Permission.TBPP.TBPP_DELETE],
      REFRESH: [Permission.TBPP.TBPP_DETAIL],
      SHARE: [Permission.TBPP.TBPP_DETAIL],
      CHECK: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
      SAVE: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
      CLOSE: [Permission.TBPP.TBPP_CREATE, Permission.TBPP.TBPP_UPDATE],
    },
  });

  const defaultTbpp: TBPP = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<TBPP>({
    version: 5,
    identifier: 'TBPP',
    apiProps: {
      url: EvidenceApiUrl.TBPP,
      ...handleApiErrors(regionApiErrors.WORK_TBPP, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__TBPP__TABLE__TITLE',
        defaultMessage: 'Práca - TBPP',
      }),
      reportTag: ExportTag.WORK_TBPP_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={Permission.TBPP.TBPP_IMPORT}
            />
            <TakeoverTableToolbarButton
              takeoverPermissionKey={Permission.Work.WORK_TAKEOVER}
              dialogText="Prevezme kategórie prác, prace aj tbbp"
            />
            {/*    <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.TBPP.TBPP_DELETE}
            /> */}
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__TBPP__DETAIL__TITLE',
          defaultMessage: 'Správa TBPP',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.TBPP.TBPP_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.TBPP}
          />
        ),
      },
      initNewItem: () => defaultTbpp,
    },
  });

  return <Evidence {...evidence} />;
};
