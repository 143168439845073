import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  FormContext,
  FormFileField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { FormImageField } from '@components/form/image-field/form-image-field';
import { Attachment } from '@models';

export const ACCEPTED_DOCUMENTS_FORMATS = [
  '.jpg',
  '.JPG',
  '.png',
  '.PNG',
  '.bmp',
  '.BMP',
  '.gif',
  '.GIF',
  '.svg',
  '.SVG',
  '.jpeg',
  '.JPEG',
  '.pdf',
  '.PDF',
  '.doc',
  '.DOC',
  '.docx',
  '.DOCX',
  '.xls',
  '.XLS',
  '.xlsx',
  '.XLSX',
];

export const DocumentsDialogText = (
  <>
    <Typography>
      Podporované formáty obrázkov: <b> .jpg, .jpeg, .png, .gif, .bmp, .svg </b>
    </Typography>
    <Typography>
      Podporované formáty ostatných súborov: <b> .doc, .docx, .xls, .xlsx </b>
    </Typography>
    <Typography>
      Maximálna velikosť: <b> 10MB</b>
    </Typography>
  </>
);

export function AttachmentsCreateDialog() {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((v: Attachment) => v);

  const isPDFAttachment = values?.file?.contentType?.includes('pdf');
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ATTACHMENTS_TABLE__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__ATTACHMENT"
              defaultMessage="Príloha"
            />
          }
          required
          accept={ACCEPTED_DOCUMENTS_FORMATS}
          notifyChange={(val) => {
            if (val) setFieldValue('signatures', null);
          }}
        />
        <FormTextField
          name="description"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
        />
      </FormPanel>
      {!isPDFAttachment && values?.file && (
        <FormImageField
          name="file"
          showFileField={false}
          //   label="Obrázok"
          height={400}
        />
      )}
    </>
  );
}
