import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useMechanismCategories } from '@modules/dict__mechanism-category/mechanism-category-api';
import { useMechanisms } from '@modules/dict__mechanisms/mechanism-api';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'type.category',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__TYPE_CATEGORY',
        defaultMessage: 'Kategória mechanizmu',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useMechanismCategories,
        multiple: false,
      }),
    },
    {
      datakey: 'type',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__TYPE',
        defaultMessage: 'Mechanizmus',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useMechanisms,
        multiple: false,
      }),
    },
    {
      datakey: 'type.licencePlate',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__TYPE__LICENCE_PLATE',
        defaultMessage: 'ŠPZ',
      }),
      width: 100,
    },
    {
      datakey: 'type.subSupplier',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__TYPE__SUPPLIER',
        defaultMessage: 'Dodávateľ mechanizmu',
      }),
      width: 250,
    },
    {
      datakey: 'distance',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__DISTANCE',
        defaultMessage: 'km - odhad',
      }),
      width: 100,
    },
    {
      datakey: 'hours',
      name: intl.formatMessage({
        id: 'ESD__MECHANISMS_COLLECTION__TABLE__COLUMN__HOURS',
        defaultMessage: 'sm/mh',
      }),
      width: 80,
    },
  ];
}
