import React, { useContext } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  ActionButton,
  DetailContext,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceContext,
  FormTextArea,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  ActionType,
  EvidenceApiUrl,
  JournalEntryState,
  JournalTypeEnum,
  PrimaryJournalState,
} from '@enums';
import { useIsAdmin } from '@utils/use-is-admin';
import { useTestAction } from '@utils/use-test-action';
import { useRemoveButtonSchema } from './remove-button-schema';

// Bozp and other records have its own toolbar remove button
const DetailToolbarRemoveButton = ({
  directDeletePermission,
  requestDeletePermission,
  journalType,
}: {
  directDeletePermission: string;
  requestDeletePermission: string;
  journalType: JournalTypeEnum;
}) => {
  const {
    del,
    source: { data },
    isExisting,
    refresh,
  } = useContext(DetailContext);
  const { apiUrl } = useContext(EvidenceContext);
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const schema = useRemoveButtonSchema();
  const isAdmin = useIsAdmin();
  const { handleTestAction } = useTestAction(journalType);

  const hasDirectDeletePermission = hasPermission(directDeletePermission);
  const hasRequestDeletePermission = hasPermission(requestDeletePermission);

  const isPrimaryJournal = apiUrl === EvidenceApiUrl.PRIMARY_JOURNAL;
  const isPartialJournal = apiUrl === EvidenceApiUrl.PARTIAL_JOURNAL;
  const isJournalEntry = apiUrl === EvidenceApiUrl.JOURNAL_ENTRY;

  const getIsDirectDel = () => {
    if (isPrimaryJournal) {
      if (data?.state === PrimaryJournalState.CLOSED) return isAdmin;
    }

    if (isPartialJournal) {
      if (data?.state === PrimaryJournalState.CLOSED) return isAdmin;
    }

    if (isJournalEntry) {
      if (data?.state === JournalEntryState.SIGNED) return isAdmin;
    }

    return true;
  };

  const isDirectDelete = getIsDirectDel();

  const requestDel = useEventCallback((formValues) => {
    return abortableFetch(`${apiUrl}/${data?.id}/delete/request`, {
      method: 'POST',
      body: JSON.stringify(formValues?.reason),
    });
  });

  const hasAnyDeletePermission =
    hasDirectDeletePermission || hasRequestDeletePermission;

  const getHideDeleteButton = () => {
    if (isPrimaryJournal || isPartialJournal) {
      return (
        data?.state === PrimaryJournalState.SIGNED ||
        data?.state === PrimaryJournalState.CANCELLED
      );
    }

    if (isJournalEntry) {
      return (
        data?.journal?.state === PrimaryJournalState.CLOSED ||
        data?.journal?.state === PrimaryJournalState.SIGNED ||
        data?.journal?.state === PrimaryJournalState.CANCELLED
      );
    }
    return data?.state === JournalEntryState.SIGNED;
  };

  const hideDeleteButton = getHideDeleteButton();

  if (hideDeleteButton || !hasAnyDeletePermission || !isExisting) return null;
  return (
    <>
      {isDirectDelete ? (
        <ActionButton
          promptKey={`DELETE_DIALOG`}
          buttonTooltip="Zmazanie"
          buttonLabel="zmazať"
          ButtonComponent={DetailToolbarButton2}
          buttonProps={{
            type: DetailToolbarButtonType.SECONDARY,
            startIcon: <DeleteOutlinedIcon />,
          }}
          submitCallback={del}
          dialogText={
            journalType === JournalTypeEnum.ENTRY
              ? 'Naozaj chcete zmazať záznam? Táto akcia je nevratná.'
              : 'Naozaj chcete zmazať denník? Táto akcia je nevratná.'
          }
          dialogTitle="Upozornenie"
          onBeforeDialog={() => handleTestAction(ActionType.DELETE)}
        />
      ) : (
        <ActionButton
          promptKey={`DELETE_DIALOG_REQUEST`}
          buttonTooltip={
            journalType === JournalTypeEnum.ENTRY
              ? 'Žiadosť o zmazanie záznamu'
              : 'Žiadosť o zmazanie denníku'
          }
          buttonLabel="zmazať"
          ButtonComponent={DetailToolbarButton2}
          buttonProps={{
            type: DetailToolbarButtonType.SECONDARY,
            startIcon: <DeleteOutlinedIcon />,
          }}
          apiCall={requestDel}
          successMessage={'Žiadosť o zmazanie bola úspešne odoslaná'}
          onSuccess={async () => {
            refresh();
            showSnackbar(
              'Žiadosť o zmazanie bola odoslaná.',
              SnackbarVariant.SUCCESS
            );
          }}
          FormFields={() => (
            <FormTextArea name="reason" label="Dôvod zmazania" required />
          )}
          dialogText={
            journalType === JournalTypeEnum.ENTRY
              ? 'Dôjde k odoslaniu žiadosti zmazanie záznamu. Ak chcete pokračovať, zadajte dôvod zmazania.'
              : 'Dôjde k odoslaniu žiadosti zmazanie denníka. Ak chcete pokračovať, zadajte dôvod zmazania.'
          }
          dialogTitle="Upozornenie"
          formValidationSchema={schema}
          // onBeforeDialog={() => handleTestAction(ActionType.DELETE)}
        />
      )}
    </>
  );
};

export default DetailToolbarRemoveButton;
