import React, { useContext } from 'react';
import {
  AuthoredObject,
  DetailContext,
  DetailHandle,
  DetailMode,
  FileRef,
  ScrollableSource,
  UserContext,
} from '@eas/common-web';
import { DocumentsDialogText } from '@composite/attachments-collection/dialogs/attachments-create-dialog';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { EvidenceApiUrl, Permission } from '@enums';
import { DocumentsDialog } from './dialogs/documents-dialog';
import { useColumns } from './documents-columns';
import { useValidationSchema } from './documents-schema';

interface CanHaveFiles extends AuthoredObject {
  files?: FileRef[];
}

export function DocumentsField<TCOMP extends CanHaveFiles>({
  evidence,
  source,
  disabled = false,
}: {
  evidence: 'BOZP' | 'OTHER_RECORDS';
  source: ScrollableSource<TCOMP>;
  disabled?: boolean;
}) {
  const {
    source: { data },
    mode,
  } = useContext<DetailHandle<TCOMP>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const isBozp = evidence === 'BOZP';

  const evidenceUrl = isBozp
    ? EvidenceApiUrl.JOURNAL_ENTRY_BOZP
    : EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS;

  const initValues = isBozp
    ? () => ({ bozp: { id: data?.id } })
    : () => ({ otherRecord: { id: data?.id } });

  return (
    <CollectionField2
      source={source}
      disabled={disabled || mode !== DetailMode.VIEW}
      title="Dokumenty"
      useColumns={useColumns}
      createAction={{
        getUrl: () => `${evidenceUrl}/file`,
        Dialog: DocumentsDialog,
        initValues,
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
        text: DocumentsDialogText,
        hide: isBozp
          ? !hasPermission(
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE
            )
          : !hasPermission(
              Permission.JournalEntryOtherRecord
                .JOURNAL_ENTRY_OTHER_RECORD_UPDATE
            ),
      }}
      readAction={{
        getUrl: () => `${evidenceUrl}/${data?.id}`,
      }}
      updateAction={{
        getUrl: () => `${evidenceUrl}/${data?.id}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (id, collectionIds) =>
          `${evidenceUrl}/file/${collectionIds[0]}`,
        label: 'Odobrať',
        hide: isBozp
          ? !hasPermission(
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE
            )
          : !hasPermission(
              Permission.JournalEntryOtherRecord
                .JOURNAL_ENTRY_OTHER_RECORD_UPDATE
            ),
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) => `${evidenceUrl}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) => `${evidenceUrl}/${collectionId}`,
      }}
    />
  );
}
