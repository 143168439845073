import { useContext } from 'react';
import { UserContext } from '@eas/common-web';

export const useIsTechnicalControllor = () => {
  const { user } = useContext(UserContext);

  const code = user?.role?.code;

  if (!code) return false;

  return code === 'ROLE_TECHNICKY_DOZOR';
};
