import { format, isBefore, parseISO } from 'date-fns';
import { useContext } from 'react';
import { LocaleContext } from '@eas/common-web';
import { CancellationRequestObject, DeletionRequestObject } from '@models';

const useDetailSubtitle = () => {
  const { locale } = useContext(LocaleContext);

  const subtitle = <
    R extends (DeletionRequestObject & CancellationRequestObject) | null
  >(
    value: R
  ) => {
    if (!value) return '';

    const {
      deletionRequested,
      cancellationRequested,
      deletionRequestedBy,
      cancellationRequestedBy,
    } = value;

    // Helper function to format the date and user info
    const formatMessage = (
      date: string,
      action: 'zmazanie' | 'zrušenie',
      user?: string
    ): string => {
      const formattedDate = format(parseISO(date), locale.dateTimeFormat);
      return `Žiadosť o ${action} odoslaná dňa: ${formattedDate}, používateľom: ${
        user ?? ''
      }`;
    };

    if (deletionRequested && !cancellationRequested) {
      return formatMessage(
        deletionRequested,
        'zmazanie',
        deletionRequestedBy?.name
      );
    }
    if (!deletionRequested && cancellationRequested) {
      return formatMessage(
        cancellationRequested,
        'zrušenie',
        cancellationRequestedBy?.name
      );
    }
    if (deletionRequested && cancellationRequested) {
      const isDeletionBeforeCancellation = isBefore(
        parseISO(deletionRequested),
        parseISO(cancellationRequested)
      );

      if (isDeletionBeforeCancellation) {
        return formatMessage(
          deletionRequested,
          'zmazanie',
          deletionRequestedBy?.name
        );
      } else {
        return formatMessage(
          cancellationRequested,
          'zrušenie',
          cancellationRequestedBy?.name
        );
      }
    }

    return '';
  };
  return subtitle;
};

export default useDetailSubtitle;
