import { useContext } from 'react';
import { UserContext } from '@eas/common-web';

export const useIsAdmin = () => {
  const { user } = useContext(UserContext);

  const code = user?.role?.code;

  if (!code) return false;

  return [
    'ROLE_ADMIN_ZHOTOVITEL',
    'ROLE_ADMIN_ZSD_VSD',
    'ROLE_SUPER_ADMIN',
  ].includes(code);
};
