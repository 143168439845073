import React from 'react';
import { FormTextField } from '@eas/common-web';

export function InspectorAssignDialog() {
  return (
    <>
      <FormTextField name="person.name" label="Meno" required />
      <FormTextField name="person.surname" label="Priezvisko" required />
      <FormTextField name="person.email" label="E-mail" />
      <FormTextField name="titul" label="Titul" />
      <FormTextField name="function" label="Funkcia" required />
      <FormTextField name="organization" label="Organizácia" required />
      <FormTextField name="controlFormat" label="Formát kontroly" required />
      <FormTextField name="mtTc" label="MT/TČ" required />
    </>
  );
}
