import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { TakeoverTableToolbarButton } from '@components/takeover-table-toolbar-button/takeover-table-toolbar-button';
import { Me, Profession } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './profession-columns';
import { Fields } from './profession-fields';
import { useValidationSchema } from './profession-schema';

export const Professions = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Profession.PROFESSION_CREATE],
      EDIT: [Permission.Profession.PROFESSION_UPDATE],
      REMOVE: [Permission.Profession.PROFESSION_DELETE],
      REFRESH: [Permission.Profession.PROFESSION_DETAIL],
      SHARE: [Permission.Profession.PROFESSION_DETAIL],
      CHECK: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
      SAVE: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
      CLOSE: [
        Permission.Profession.PROFESSION_CREATE,
        Permission.Profession.PROFESSION_UPDATE,
      ],
    },
  });

  const defaultProfession: Profession = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<Profession>({
    version: 4,
    identifier: 'PROFESSION',
    apiProps: {
      url: EvidenceApiUrl.PROFESSION,
      ...handleApiErrors(regionApiErrors.PROFESSION, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PROFESSION__TABLE__TITLE',
        defaultMessage: 'Zoznam pracovných pozícii',
      }),
      reportTag: ExportTag.PROFESSION_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={Permission.Profession.PROFESSION_IMPORT}
            />
            <TakeoverTableToolbarButton
              takeoverPermissionKey={Permission.Profession.PROFESSION_TAKEOVER}
            />
            {/*     <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Profession.PROFESSION_DELETE}
            /> */}
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PROFESSION__DETAIL__TITLE',
          defaultMessage: 'Správa pracovných pozícii',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.Profession.PROFESSION_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.PROFESSION}
          />
        ),
      },
      initNewItem: () => defaultProfession,
    },
  });

  return <Evidence {...evidence} />;
};
