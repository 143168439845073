import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  FormAutocomplete,
  FormContext,
  FormDecimalField2,
  FormLocalDateField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useAbsences } from '@modules/dict__absence/absence-api';
import { usePersons } from '@modules/person/person-api';
import PersonFreetextField from '@components/person-freetext-field/person-freetext-field';
import { Timesheet } from '@models';
import { useTimesheetList } from '../timesheet-api';

export function TimesheetUpdateDialog() {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((v: Timesheet) => v);
  const {
    source: { data },
  } = useContext(DetailContext);

  const { result } = useTimesheetList({
    skip: false,
    journalEntryId: data?.id,
  });

  const allPeopleInTimesheetIds = (result?.items ?? []).map((r) => r.person.id);

  // filter is added, so we have in autocomplete only people which are not yet assigned in timesheet
  const persons = usePersons({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allPeopleInTimesheetIds,
          },
        ],
      },
    ],
  });

  const absences = useAbsences();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormLocalDateField
          name="date"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__DATE"
              defaultMessage="Dátum"
            />
          }
          disabled
        />
        <PersonFreetextField
          autocompleteSource={persons}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__PERSON"
              defaultMessage="Osoba"
            />
          }
          name="person"
          visible
        />
        {values?.person && (
          <>
            <FormTextField
              name="person.profession.name"
              label={
                <FormattedMessage
                  id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__PROFESSION"
                  defaultMessage="Pracovná pozícia"
                />
              }
              disabled
            />
            <FormTextField
              name="person.supplier.name"
              label={
                <FormattedMessage
                  id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__SUPPLIER"
                  defaultMessage="Spoločnosť"
                />
              }
              disabled
            />
          </>
        )}

        <FormDecimalField2
          name="hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__HOURS"
              defaultMessage="Odpracované hodiny"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
          negative={false}
        />
        <FormDecimalField2
          name="overtime"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__OVERTIME"
              defaultMessage="Nadčas"
            />
          }
          required
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
          negative={false}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__ABSENCE"
            defaultMessage="Neprítomnosť"
          />
        }
      >
        <FormAutocomplete
          source={absences}
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE"
              defaultMessage="Neprítomnosť"
            />
          }
          name="absence.type"
          notifyChange={(val) => {
            if (!val) setFieldValue('absence', null);
          }}
        />
        <FormDecimalField2
          name="absence.hours"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_HOURS"
              defaultMessage="Neprítomnosť - hodiny"
            />
          }
          decimalDigits={1}
          fixedDecimalDigits={false}
          maxValue={24}
          required={!!values?.absence?.type}
        />
        <FormTextField
          name="absence.comment"
          label={
            <FormattedMessage
              id="ESD__TIMESHEET__COLLECTION__FIELD_LABEL__ABSENCE_COMMENT"
              defaultMessage="Komentár"
            />
          }
        />
      </FormPanel>
    </>
  );
}
