import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormCustomField,
  FormLocalDateField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextField,
  TextField,
  UserContext,
} from '@eas/common-web';
import { usePrimaryJournalState } from '@modules/primary-journals/primary-journal-api';
import { useUsers } from '@modules/users/user-api';
import { MechanismField } from '@composite/mechanisms-collection/mechanism-field';
import { NotesField } from '@composite/notes-collection/notes-field';
import { TimesheetsField } from '@composite/timesheets-collection/timesheet-field';
import { WorkDoneField } from '@composite/work-done-collection/work-done-field';
import FieldCrudButtons from '@components/field-crud-buttons/field-crud-buttons';
import { Redirect } from '@components/redirect/redirect';
import { JournalEntry, Me, PartialJournal, PrimaryJournal } from '@models';
import { EvidenceBrowserUrl, JournalTypeEnum, Permission } from '@enums';
import { userLabelMapper } from '@utils/label-mapper';
import { useIsTechnicalControllor } from '@utils/use-is-technical-controllor';
import { useJournalEntryStates, useJournalTypes } from '../journal-entry-api';
import { useWeatherColumns } from '../journal-entry-columns';
import {
  MECHANISM,
  TIMESHEET,
  WORK_DONE,
} from '../journal-entry-hooked-sources';

export const weatherCodes = {
  '01d': 'jasno',
  '01n': 'jasno',
  '02d': 'málo oblakov',
  '02n': 'málo oblakov',
  '03d': 'polojasno',
  '03n': 'polojasno',
  '04d': 'oblačno',
  '04n': 'oblačno',
  '09d': 'prehánky',
  '09n': 'prehánky',
  '10d': 'dážď',
  '10n': 'dážď',
  '11d': 'búrka',
  '11n': 'búrka',
  '13d': 'sneh',
  '13n': 'sneh',
  '50d': 'hmla',
  '50n': 'hmla',
};

export function JournalEntryOverviewFields() {
  const {
    source: { data },
    mode,
    isLocked,
  } = useContext<DetailHandle<JournalEntry>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const isTechnicalControllor = useIsTechnicalControllor();

  const mechanismSource = hookedSources[MECHANISM]?.source;
  const workDoneSource = hookedSources[WORK_DONE]?.source;
  const timesheetsSource = hookedSources[TIMESHEET]?.source;

  const journalTypes = useJournalTypes();
  const journalStates = usePrimaryJournalState();
  const users = useUsers();
  const journalEntryStates = useJournalEntryStates();

  const weatherColumns = useWeatherColumns();

  const parentJournal = data?.journal;

  const isParentPrimaryJournal =
    data?.journal?.journalType === JournalTypeEnum.PRIMARY;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__PANEL__JOURNAL_ENTRY"
            defaultMessage="Denný záznam"
          />
        }
      >
        <FormTextField
          name="construction.name"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__JOURNAL_NAME"
              defaultMessage="Názov stavby"
            />
          }
          disabled
          after={
            <Redirect
              url={
                EvidenceBrowserUrl.PRIMARY_JOURNAL
                /*   parentJournal?.journalType === JournalTypeEnum.PRIMARY
                  ? EvidenceBrowserUrl.PRIMARY_JOURNAL
                  : EvidenceBrowserUrl.PARTIAL_JOURNAL */
              }
              id={
                parentJournal && 'parent' in parentJournal
                  ? parentJournal?.parent?.id ?? ''
                  : parentJournal?.id ?? ''
              }
            />
          }
        />

        {isParentPrimaryJournal ? (
          <FormAutocomplete
            name="journal.leader"
            label={
              <FormattedMessage
                id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__JOURNAL_PARENT_LEADER"
                defaultMessage="Hlavný stavbyvedúci"
              />
            }
            source={users}
            disabled
            labelMapper={userLabelMapper}
          />
        ) : (
          <FormAutocomplete
            name="journal.parent.leader"
            label={
              <FormattedMessage
                id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__JOURNAL_LEADER"
                defaultMessage="Stavbyvedúci dodávateľa"
              />
            }
            source={users}
            disabled
            labelMapper={userLabelMapper}
          />
        )}

        <FormLocalDateField
          name="validityDate"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__VALIDITY_DATE"
              defaultMessage="Dátum denného záznamu"
            />
          }
          required
          disabled
        />
        <FormTextField
          name="sequenceNumber"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__SEQUENCE_NUMBER"
              defaultMessage="Číslo denného záznamu"
            />
          }
          disabled
        />
        <FormSelect
          name="journal.journalType"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__JOURNAL_TYPE"
              defaultMessage="Typ denníka"
            />
          }
          source={journalTypes}
          valueIsId
          disabled
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav denného záznamu"
            />
          }
          source={journalEntryStates}
          valueIsId
          disabled
        />
        <FormSelect
          name="journal.state"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__JOURNAL_STATE"
              defaultMessage="Stav denníka"
            />
          }
          source={journalStates}
          valueIsId
          disabled
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__PANEL_WEATHER"
            defaultMessage="Počasie"
          />
        }
        expandable
        defaultExpanded={false}
      >
        <FormTableField
          name="weather.reports"
          columns={weatherColumns}
          disabled
          showToolbar={false}
          showDetailBtnCond={stubFalse}
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL_REPORTS"
              defaultMessage="Záznamy"
            />
          }
        />
        <FormTextField
          name="weatherDescription"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__WEATHER_DESCRIPTION"
              defaultMessage="Poznámka"
            />
          }
          after={<FieldCrudButtons />}
        />
      </FormPanel>

      {hasPermission(
        Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_LIST
      ) &&
        data?.validityDate &&
        data.id && (
          <TimesheetsField
            source={timesheetsSource}
            currentJournalId={data.id}
            validityDate={data.validityDate}
            disabled={
              mode !== DetailMode.VIEW || isLocked || isTechnicalControllor
            }
            defaultExpanded={false}
          />
        )}

      {hasPermission(
        Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_LIST
      ) && (
        <MechanismField
          source={mechanismSource}
          currentJournalId={data?.id ?? ''}
          disabled={
            mode !== DetailMode.VIEW || isLocked || isTechnicalControllor
          }
          defaultExpanded={false}
        />
      )}

      {hasPermission(
        Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_LIST
      ) && (
        <WorkDoneField
          source={workDoneSource}
          currentJournalId={data?.id ?? ''}
          disabled={
            mode !== DetailMode.VIEW || isLocked || isTechnicalControllor
          }
          defaultExpanded={false}
        />
      )}

      {hasPermission(Permission.JournalEntryNote.JOURNAL_ENTRY_NOTE_LIST) && (
        <NotesField
          disabled={
            mode !== DetailMode.VIEW || isLocked || isTechnicalControllor
          }
        />
      )}
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__PANEL_COMMAND_B_NUMBER"
            defaultMessage="Číslo B - príkazu"
          />
        }
        expandable
      >
        <FormTextField
          name="directiveB"
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__COMMAND_B_NUMBER"
              defaultMessage="Číslo B - príkazu"
            />
          }
          after={<FieldCrudButtons />}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__JOURNAL_ENTRY__DETAIL__PANEL_GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormLocalDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Vytvorenie denného záznamu"
            />
          }
          disabled
        />
        <FormTextField
          name="createdBy.name"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__CREATED_BY"
              defaultMessage="Autor denného záznamu"
            />
          }
          disabled
        />
        <FormLocalDateTimeField
          name="updated"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__UPDATED"
              defaultMessage="Posledná úprava denného záznamu"
            />
          }
          disabled
        />
        <FormTextField
          name="updatedBy.name"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__UPDATED_BY"
              defaultMessage="Autor úpravy denného záznamu"
            />
          }
          disabled
        />
        <FormCustomField
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__PARENT_TENANT"
              defaultMessage="Vlastník stavebného denníka"
            />
          }
        >
          <TextField
            value={
              (data?.journal as PrimaryJournal)?.tenant?.name ||
              (data?.journal as PartialJournal)?.parent?.tenant?.name
            }
            disabled
          />
        </FormCustomField>
      </FormPanel>
    </>
  );
}
