import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { usePersons } from '@modules/person/person-api';
import { personLabelMapper } from '@utils/label-mapper';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__TABLE__COLUMN__NAME',
        defaultMessage: 'Meno',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 100,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__WORK_GROUP__TABLE__COLUMN__SURNAME',
        defaultMessage: 'Priezvisko',
      }),
      datakey: 'surname',
      sortkey: 'surname',
      width: 100,
    },
  ];
}
