import React from 'react';
import { AttachmentsField } from '@composite/attachments-collection/attachments-field';
import { useIsTechnicalControllor } from '@utils/use-is-technical-controllor';

export function JournalEntryAttachmentsFields() {
  const isTechnicalControllor = useIsTechnicalControllor();
  return (
    <>
      <AttachmentsField disabled={isTechnicalControllor} />
    </>
  );
}
