import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, FormPanel, FormTextField } from '@eas/common-web';
import { WorkGroupField } from '@composite/work-group-collection/work-group-field';

export const Fields = () => {
  const { isExisting } = useContext(DetailContext);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_GROUP__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov pracovnej skupiny"
            />
          }
          required
        />
      </FormPanel>

      {isExisting && <WorkGroupField />}
    </>
  );
};
