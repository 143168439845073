/**
 * Journal entry other records permissions
 */
export enum PermissionJournalEntryOtherRecords {
  /**
   * Vloženie Ostatných záznamů
   */
  JOURNAL_ENTRY_OTHER_RECORD_CREATE = 'JOURNAL_ENTRY_OTHER_RECORD_CREATE',

  /**
   * Zmazanie BOZP denného záznamu
   */
  JOURNAL_ENTRY_OTHER_RECORD_DELETE = 'JOURNAL_ENTRY_OTHER_RECORD_DELETE',

  /**
   * žádost - Zmazanie BOZP denného záznamu
   */
  JOURNAL_ENTRY_OTHER_RECORD_DELETE_REQUEST = 'JOURNAL_ENTRY_OTHER_RECORD_DELETE_REQUEST',

  /**
   * Podpisovanie BOZP
   */
  JOURNAL_ENTRY_OTHER_RECORD_SIGN = 'JOURNAL_ENTRY_OTHER_RECORD_SIGN',

  /**
   * List BOZP
   */
  JOURNAL_ENTRY_OTHER_RECORD_LIST = 'JOURNAL_ENTRY_OTHER_RECORD_LIST',

  /**
   * Edit BOZP
   */
  JOURNAL_ENTRY_OTHER_RECORD_UPDATE = 'JOURNAL_ENTRY_OTHER_RECORD_UPDATE',

  /**
   * Detail BOZP
   */
  JOURNAL_ENTRY_OTHER_RECORD_DETAIL = 'JOURNAL_ENTRY_OTHER_RECORD_DETAIL',
}
