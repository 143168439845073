import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  Filter,
  ScrollableSource,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Bozp, PersonType } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { PersonAssignDialog } from './dialogs/person-assign-dialog';
import { useColumns } from './person-columns';
import { useFilterFields } from './person-filter-fields';
import { useValidationSchema } from './person-schema';

interface PersonFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField2<PersonType>>
  > {
  refFilters?: Filter[];
  source: ScrollableSource<PersonType>;
  evidence: 'BOZP' | 'OTHER_RECORDS';

  disabled?: boolean;
}

export function PersonField2({
  disabled,
  source,
  evidence,
  ...overrides
}: PersonFieldProps) {
  const { mode } = useContext<DetailHandle<Bozp>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const isBozp = evidence === 'BOZP';

  const evidenceUrl = isBozp
    ? EvidenceApiUrl.JOURNAL_ENTRY_BOZP
    : EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS;

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__PERSON_COLLECTION"
          defaultMessage="Oboznámené osoby"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={disabled || mode !== DetailMode.VIEW}
      createAction={{
        getUrl: (id) => evidenceUrl + '/person/' + id,
        Dialog: PersonAssignDialog,
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
        useApi: (getUrl) => {
          return {
            callApi: (id: string, formData) =>
              abortableFetch(`${getUrl(id)}`, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify(formData.person),
              }),
          };
        },
        hide: isBozp
          ? !hasPermission(
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE
            )
          : !hasPermission(
              Permission.JournalEntryOtherRecord
                .JOURNAL_ENTRY_OTHER_RECORD_UPDATE
            ),
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (id, collectionIds) =>
          `${evidenceUrl}/person/${collectionIds[0]}`,
        label: 'Odobrať',
        hide: isBozp
          ? !hasPermission(
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE
            )
          : !hasPermission(
              Permission.JournalEntryOtherRecord
                .JOURNAL_ENTRY_OTHER_RECORD_UPDATE
            ),
        /*  useApi: (getUrl) => {
          return {
            callApi: (id: string, collectionIds: string | string[]) =>
              abortableFetch(`${getUrl(id, collectionIds)}`, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify(collectionIds[0]),
              }),
          };
        }, */
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
