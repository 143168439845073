/**
 * Journal entry permissions
 */
export enum PermissionJournalEntry {
  /**
   * Prehľad denných záznamov
   */
  JOURNAL_ENTRY_LIST = 'JOURNAL_ENTRY_LIST',

  /**
   * Detail denného záznamu
   */
  JOURNAL_ENTRY_DETAIL = 'JOURNAL_ENTRY_DETAIL',

  /**
   * Vytvorenie denného záznamu
   */
  JOURNAL_ENTRY_CREATE = 'JOURNAL_ENTRY_CREATE',

  /**
   * Editácia denného záznamu
   */
  JOURNAL_ENTRY_UPDATE = 'JOURNAL_ENTRY_UPDATE',

  /**
   * Zmazanie denného záznamu
   */
  JOURNAL_ENTRY_DELETE = 'JOURNAL_ENTRY_DELETE',

  /**
   * žádost -  Zmazanie denného záznamu
   */
  JOURNAL_ENTRY_DELETE_REQUEST = 'JOURNAL_ENTRY_DELETE_REQUEST',

  /**
   * Podpisovanie denného záznamu
   */
  JOURNAL_ENTRY_SIGN = 'JOURNAL_ENTRY_SIGN',

  /**
   * Zatvorenie denného záznamu
   */
  JOURNAL_ENTRY_CLOSE = 'JOURNAL_ENTRY_CLOSE',

  /**
   * Duplikácia denného záznamu
   */
  JOURNAL_ENTRY_DUPLICATE = 'JOURNAL_ENTRY_DUPLICATE',
}
