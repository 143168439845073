import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormFileField,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { FormImageField } from '@components/form/image-field/form-image-field';
import { Attachment } from '@models';

export function AttachmentsReadDialog() {
  const values = useFormSelector((v: Attachment) => v);

  const isImageAttachment = values?.file?.contentType?.includes('image');

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ATTACHMENTS_TABLE__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__ATTACHMENT"
              defaultMessage="Príloha"
            />
          }
          required
          disabled
        />
        <FormTextField
          name="description"
          label={
            <FormattedMessage
              id="ESD__ATTACHMENTS_TABLE__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
          disabled
        />
      </FormPanel>

      {isImageAttachment && values?.file && (
        <FormImageField
          name="file"
          showFileField={false}
          //   label="Obrázok"
          height={400}
        />
      )}
    </>
  );
}
