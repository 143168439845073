import { useIntl } from 'react-intl';
import { DomainObject, TableFieldColumn } from '@eas/common-web';
import { FileTableActionCell } from '@components/file-table-action-cell/file-table-action-cell';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__BOZP_TABLE__COLUMN__DOWNLOAD',
        defaultMessage: 'Stiahnuť',
      }),
      datakey: 'file.id',
      width: 70,
      CellComponent: FileTableActionCell,
    },

    {
      name: intl.formatMessage({
        id: 'ESD__BOZP_TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'file.name',
      width: 300,
    },
  ];
}
