import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { useSignatureTypes } from '@composite/signatures-field/signatures-api';
import { personLabelMapper } from '@utils/label-mapper';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'personToSign.person',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Osoba',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePersons,
        multiple: false,
        labelMapper: personLabelMapper,
      }),
    },
    {
      datakey: 'personToSign.person.freeText',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Freetext',
      }),
      width: 80,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'personToSign.person.profession.name',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Profesia',
      }),
      width: 200,
    },
    {
      datakey: 'personToSign.person.supplier.name',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Dodávateľ',
      }),
      width: 250,
    },

    {
      datakey: 'personToSign.signature',
      name: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__COLUMN__HEADER',
        defaultMessage: 'Podpísané',
      }),
      width: 80,
      CellComponent: function Cell(props) {
        const isSigned = !!props?.value?.id;

        return <TableFieldCells.BooleanCell {...props} value={isSigned} />;
      },
    },
    {
      datakey: 'personToSign.signature.type',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Typ podpisu',
      }),
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(useSignatureTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__CREATED',
        defaultMessage: 'Dátum a čas podpisu',
      }),
      datakey: 'personToSign.signature.created',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}
