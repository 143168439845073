import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { ConstructionType, PrimaryJournalState } from '@enums';

export function usePrimaryJournalState() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: PrimaryJournalState.CREATED, name: 'Vytvorený' },
    { id: PrimaryJournalState.OPENED, name: 'Otvorený' },
    { id: PrimaryJournalState.CLOSED, name: 'Uzavretý' },
    { id: PrimaryJournalState.CLOSING, name: 'Uzatváranie' },
    { id: PrimaryJournalState.SIGNED, name: 'Podpísaný' },
    { id: PrimaryJournalState.REOPENED, name: 'Znovu otvorený' },
    { id: PrimaryJournalState.CANCELLED, name: 'Zrušený' },
  ]);
}
export function useConstructionTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ConstructionType.LONG_TERM, name: 'Dlhotrvajúca' },
    { id: ConstructionType.SHORT_TERM, name: 'Líniová' },
  ]);
}
