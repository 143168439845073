import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const SIGN: MessageType<string> = {
  SUCCESS: ['BOZP bol úspešně podpísaný', SnackbarVariant.SUCCESS],
  PERSON_TO_SIGN_NOT_EXIST: [
    'Nepodpísané. Prihlásená osoba sa nenachádza v BOZP zázname.',
    SnackbarVariant.ERROR,
  ],
  PERSON_TO_SIGN_IS_SIGNED: [
    'Nepodpísané. Prihlásená osoba je už podpísaná.',
    SnackbarVariant.ERROR,
  ],
};

export default { SIGN };
