import * as Yup from 'yup';
import { Inspector, PersonType } from '@models';

export function useValidationSchema() {
  return Yup.object<Inspector>().shape({
    person: Yup.object<PersonType>().shape({
      name: Yup.string().nullable().required(),
      surname: Yup.string().nullable().required(),
    }),
    function: Yup.string().nullable().required(),
    organization: Yup.string().nullable().required(),
    controlFormat: Yup.string().nullable().required(),
    mtTc: Yup.string().nullable().required(),
  });
}
