import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailActionButton,
  DetailContext,
  NavigationContext,
  SecondaryDetailActionbarButton,
  SnackbarContext,
} from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { ErrorObject } from '@models';
import { EvidenceBrowserUrl, Messages } from '@enums';
import { useSignBOZPByTimestamp } from './dialog-actions/sign-timestamp-hook';

export function BozpActionbar() {
  const {
    source: { data },
  } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);

  const { callApi, showButton: showSignButton } = useSignBOZPByTimestamp();

  return (
    <>
      {data?.journalEntry?.id && (
        <TertiaryDetailActionbarButton
          label="Späť na denný záznam"
          onClick={() =>
            navigate(
              `${EvidenceBrowserUrl.JOURNAL_ENTRY}/${data?.journalEntry?.id}?view=JOURNAL_ENTRY_BOZP`
            )
          }
          isLocked={false}
        />
      )}
      {showSignButton && (
        <DetailActionButton
          promptKey="SIGN_BOZP"
          dialogTitle={
            <FormattedMessage
              id="ESD_BOZP__ACTIONBAR__SIGN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_BOZP__ACTIONBAR__SIGN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať BOZP?"
            />
          }
          buttonLabel={
            <FormattedMessage
              id="ESD_BOZP__ACTIONBAR__SIGN__BUTTON_LABEL"
              defaultMessage="Podpísať časovou pečiatkou"
            />
          }
          apiCall={callApi}
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
          onError={async (err: ErrorObject<string>) =>
            showSnackbar(
              ...(err.code && err.code in Messages.Bozp.SIGN
                ? Messages.Bozp.SIGN[err.code]
                : Messages.Common.UNEXPECTED_ERROR)
            )
          }
        />
      )}
    </>
  );
}
