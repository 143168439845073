import { useContext } from 'react';
import { PartialJournalState } from 'src/enums/partial-journal-state';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { Me, PartialJournal } from '@models';
import { EvidenceApiUrl, Permission, PrimaryJournalState } from '@enums';

export function useClosePartialJournalDialog() {
  const {
    source: { data },
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const callApi = (id: string) => {
    return abortableFetch(`${EvidenceApiUrl.PARTIAL_JOURNAL}/${id}/close`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const showButton =
    data?.parent?.state !== PrimaryJournalState.CANCELLED &&
    (data?.state === PartialJournalState.OPENED ||
      data?.state === PartialJournalState.REOPENED) &&
    hasPermission(Permission.PartialJournal.PARTIAL_JOURNAL_CLOSE);

  return { callApi, showButton };
}
