import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormDecimalField2,
  FormPanel,
  FormTextField,
  eqFilterParams,
  useFormSelector,
} from '@eas/common-web';
import { useMechanismCategories } from '@modules/dict__mechanism-category/mechanism-category-api';
import { useMechanisms } from '@modules/dict__mechanisms/mechanism-api';
import { FormFreeTextFieldParentState } from '@components/form/form-free-text-field/form-free-text-field-parent-state';
import { JournalEntryMechanism } from '@models';

export function MechanismCreateDialog() {
  const { type } = useFormSelector((values: JournalEntryMechanism) => ({
    type: values.type,
  }));

  const mechanismCategories = useMechanismCategories();
  const mechanisms = useMechanisms(
    type
      ? eqFilterParams({ field: 'category.id', value: type?.category?.id })
      : undefined
  );

  const [isFreeText, setIsFreeText] = useState(!!type?.freeText);
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Mechanizmus"
          />
        }
      >
        <FormFreeTextFieldParentState
          isFreeText={isFreeText}
          setIsFreetext={setIsFreeText}
          autocompleteProps={{
            source: mechanismCategories,
            label: 'Kategórie mechanizmu',
            name: 'type.category',
            required: true,
          }}
          textFieldProps={{
            name: 'type.category.name',
            label: 'Kategórie mechanizmu - manuálne',
            required: true,
          }}
          fieldsToReset={['type']}
        />

        <FormFreeTextFieldParentState
          isFreeText={isFreeText}
          setIsFreetext={setIsFreeText}
          autocompleteProps={{
            source: mechanisms,
            label: 'Mechanizmus',
            name: 'type',
            required: true,
          }}
          textFieldProps={{
            name: 'type.name',
            label: 'Mechanizmus - manuálne',
            required: true,
          }}
          fieldsToReset={['type']}
        />

        <FormFreeTextFieldParentState
          isFreeText={isFreeText}
          setIsFreetext={setIsFreeText}
          autocompleteProps={{
            name: 'type',
            source: mechanisms,
            labelMapper: (option) => option.licencePlate ?? '',

            label: 'ŠPZ',
            required: true,
          }}
          textFieldProps={{
            name: 'type.licencePlate',
            label: 'ŠPZ - manuálne',
            required: true,
          }}
          fieldsToReset={['type']}
        />

        <FormTextField
          name="type.subSupplier"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__TYPE"
              defaultMessage="Dodávateľ mechanizmu"
            />
          }
          disabled={!isFreeText}
          required
        />
        <FormTextField
          name="attendant"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__ATTENDANT"
              defaultMessage="Obsluha mechanizmu"
            />
          }
        />
        <FormDecimalField2
          name="distance"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__DISTANCE"
              defaultMessage="km - odhad"
            />
          }
          negative={false}
          fixedDecimalDigits={false}
          required
        />
        <FormDecimalField2
          name="hours"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__HOURS"
              defaultMessage="sm/mh"
            />
          }
          required
          negative={false}
          fixedDecimalDigits={false}
        />
      </FormPanel>
    </>
  );
}
