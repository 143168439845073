import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  Filter,
  ScrollableSource,
} from '@eas/common-web';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Inspector, OtherRecord } from '@models';
import { EvidenceApiUrl } from '@enums';
import { InspectorAssignDialog } from './dialogs/inspector-assign-dialog';
import { useInspectorColumns } from './inspector-columns';
import { useValidationSchema } from './inspector-schema';

interface PersonFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField2<Inspector>>
  > {
  refFilters?: Filter[];
  source: ScrollableSource<Inspector>;

  disabled?: boolean;
}

export function InspectorField({
  source,
  disabled,
  ...overrides
}: PersonFieldProps) {
  const {
    mode,
    source: { data },
  } = useContext<DetailHandle<OtherRecord>>(DetailContext);

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__PERSON_COLLECTION"
          defaultMessage="Inšpektori"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useInspectorColumns}
      disabled={disabled || mode !== DetailMode.VIEW}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS + '/inspector',
        Dialog: InspectorAssignDialog,
        initValues: () => ({
          otherRecord: { id: data?.id, signed: data?.signed },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (id, collectionIds) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS}/inspector/${collectionIds[0]}`,
        label: 'Odobrať',
        /*  useApi: (getUrl) => {
          return {
            callApi: (id: string, collectionIds: string | string[]) =>
              abortableFetch(`${getUrl(id, collectionIds)}`, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify(collectionIds[0]),
              }),
          };
        }, */
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.PERSON}/assignment/${collectionId}`,
      }}
      {...overrides}
    />
  );
}
