import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { Role } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { Fields } from './role-fields';

export function Roles() {
  const intl = useIntl();
  const {
    columnName,
    columnCode,
    columnOrder,
    columnActive,
    columnCreatedBy,
    columnCreated,
    columnUpdatedBy,
    columnUpdated,
  } = DictionaryEvidence.useDictionaryColumns<Role>();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Role.ROLE_CREATE],
      EDIT: [Permission.Role.ROLE_UPDATE],
      REMOVE: [Permission.Role.ROLE_DELETE],
      REFRESH: [Permission.Role.ROLE_DETAIL],
      SHARE: [Permission.Role.ROLE_DETAIL],
      CHECK: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
      SAVE: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
      CLOSE: [Permission.Role.ROLE_CREATE, Permission.Role.ROLE_UPDATE],
    },
  });

  const evidence = DictionaryEvidence.useDictionaryEvidence<Role>({
    version: 1,
    identifier: 'ROLES',
    apiProps: {
      url: EvidenceApiUrl.ROLES,
    },
    tableProps: {
      columns: [
        columnName,
        columnCode,
        columnOrder,
        columnActive,
        columnCreatedBy,
        columnCreated,
        columnUpdatedBy,
        columnUpdated,
      ],
      tableName: intl.formatMessage({
        id: 'IP__ROLES__TABLE__TITLE',
        defaultMessage: 'Role',
      }),
      // reportTag: ExportTag.ROLES_GRID,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
      showBulkActionButton: false,
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'IP__ROLES__DETAIL__TITLE',
          defaultMessage: 'Správa role',
        }),
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
}
