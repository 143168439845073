import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CollectionField,
  DetailContext,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { OTHER_RECORDS } from '@modules/journal-entry/journal-entry-hooked-sources';
import { Me } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { OtherRecordsCreateDialog } from './dialogs/other-records-create-dialog';
import { useColumns } from './other-records-colums';
import { useValidationSchema } from './other-records-schema';

type Props = {
  disabled?: boolean;
};

export function OtherRecordsField({ disabled }: Props) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    isLocked,
    mode,
  } = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const source = hookedSources[OTHER_RECORDS]?.source;

  return (
    <CollectionField
      title={<FormattedMessage id="ESD__OTHER_RECORDS__TITLE" />}
      source={source}
      useColumns={useColumns}
      disabled={isLocked || mode !== DetailMode.VIEW || !!disabled}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/other/record',
        Dialog: OtherRecordsCreateDialog,
        initValues: () => ({
          journalEntry: { id: data.id },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
        title: 'Vytvorenie - Ostatné záznamy',
        hide: !hasPermission(
          Permission.JournalEntryOtherRecord.JOURNAL_ENTRY_OTHER_RECORD_CREATE
        ),
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/other/record/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/other/record/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/other/record/${collectionId}`,
        label: 'Odobrať',
        hide: true,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/other/record/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/other/record/${collectionId}`,
      }}
    />
  );
}
