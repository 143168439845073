import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { Signature } from '@models';
import { personLabelMapper } from '@utils/label-mapper';
import { useSignatureTypes } from './signatures-api';

export function useSignaturesColumns(): TableFieldColumn<Signature>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__SIGNED_BY',
        defaultMessage: 'Podpísané osobou',
      }),
      datakey: 'signedBy',

      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: usePersons,
        labelMapper: personLabelMapper,
        multiple: false,
      }),
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__TYPE',
        defaultMessage: 'Typ podpisu',
      }),
      datakey: 'type',
      width: 150,
      CellComponent: TableFieldCells.useSelectCellFactory(useSignatureTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__CREATED',
        defaultMessage: 'Vytvorenie',
      }),
      datakey: 'created',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__UPDATED',
        defaultMessage: 'Posledná úprava',
      }),
      datakey: 'updated',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
  ];
}
