import { isEmpty } from 'lodash';
import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { OtherRecord } from '@models';
import { EvidenceApiUrl, JournalEntryState, Permission } from '@enums';
import { FILES, INSTRUCTED_PERSON } from '../other-record-hooked-sources';

export function useSignOtherRecordByTimestamp() {
  const { user, hasPermission } = useContext(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<OtherRecord>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const instructedPersonSource = hookedSources[INSTRUCTED_PERSON]?.source;
  const filesCount = hookedSources[FILES]?.source.count;

  const callApi = (id: string) => {
    return abortableFetch(
      `${EvidenceApiUrl.JOURNAL_ENTRY_OTHER_RECORDS}/${id}/sign`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          type: 'TIMESTAMP',
          signedBy: { id: user?.person?.id },
          timestamp: new Date(),
        }),
      }
    );
  };

  const showButton =
    (data?.description || !!filesCount) &&
    data?.journalEntry?.state === JournalEntryState.OPENED &&
    // Check if instructed person exists
    !!instructedPersonSource.count &&
    hasPermission(
      Permission.JournalEntryOtherRecord.JOURNAL_ENTRY_OTHER_RECORD_SIGN
    );

  return { callApi, showButton };
}
