import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { useSignatureTypes } from '@composite/signatures-field/signatures-api';

export function useInspectorColumns<
  T extends DomainObject
>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'person.name',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Meno',
      }),
      width: 200,
    },
    {
      datakey: 'person.surname',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__SURNAME',
        defaultMessage: 'Priezvisko',
      }),
      width: 200,
    },
    {
      datakey: 'person.email',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__EMAIL',
        defaultMessage: 'E-mail',
      }),
      width: 200,
    },
    {
      datakey: 'signature',
      name: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__COLUMN__HEADER',
        defaultMessage: 'Podpísané',
      }),
      width: 80,
      CellComponent: function Cell(props) {
        const isSigned = !!props?.value?.id;

        return <TableFieldCells.BooleanCell {...props} value={isSigned} />;
      },
    },
    {
      datakey: 'signature.type',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Typ podpisu',
      }),
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(useSignatureTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ESD__SIGNATURES__TABLE__COLUMN__CREATED',
        defaultMessage: 'Dátum a čas podpisu',
      }),
      datakey: 'signature.created',
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'title',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__TITLE',
        defaultMessage: 'Titul',
      }),
      width: 100,
    },
    {
      datakey: 'function',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__FUNCTION',
        defaultMessage: 'Funkcia',
      }),
      width: 200,
    },
    {
      datakey: 'organization',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__ORGANIZATION',
        defaultMessage: 'Organizácia',
      }),
      width: 200,
    },

    {
      datakey: 'controlFormat',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__CONTROL_FORMAT',
        defaultMessage: 'Formát kontroly',
      }),
      width: 250,
    },
    {
      datakey: 'mtTc',
      name: intl.formatMessage({
        id: 'ESD__INSPECTORS_COLLECTION__TABLE__COLUMN__PHONE_NUMBER',
        defaultMessage: 'MT/TČ',
      }),
      width: 150,
    },
  ];
}
