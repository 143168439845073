/**
 * Partial journal permissions
 */
export enum PermissionPartialJournal {
  /**
   * Prehľad dielčích denníkov
   */
  PARTIAL_JOURNAL_LIST = 'PARTIAL_JOURNAL_LIST',

  /**
   * Prehľad pridelených dielčích denníkov
   */
  PARTIAL_JOURNAL_READ_ASSIGNED = 'PARTIAL_JOURNAL_READ_ASSIGNED',
  /**
   * Detail dielčieho denníka
   */
  PARTIAL_JOURNAL_DETAIL = 'PARTIAL_JOURNAL_DETAIL',

  /**
   * Vytvorenie dielčieho denníka
   */
  PARTIAL_JOURNAL_CREATE = 'PARTIAL_JOURNAL_CREATE',

  /**
   * Editácia dielčieho denníka
   */
  PARTIAL_JOURNAL_UPDATE = 'PARTIAL_JOURNAL_UPDATE',

  /**
   * Zmazanie dielčieho denníka
   */
  PARTIAL_JOURNAL_DELETE = 'PARTIAL_JOURNAL_DELETE',

  /**
   * žádost -  Zmazanie dielčieho denníka
   */
  PARTIAL_JOURNAL_DELETE_REQUEST = 'PARTIAL_JOURNAL_DELETE_REQUEST',

  /**
   * Zatvorenie dielčieho denníka
   */
  PARTIAL_JOURNAL_CLOSE = 'PARTIAL_JOURNAL_CLOSE',

  /**
   * Zrušenie dielčieho denníka
   */
  PARTIAL_JOURNAL_CANCEL = 'PARTIAL_JOURNAL_CANCEL',
  /**
   * žádost o Zrušenie dielčieho denníka
   */
  PARTIAL_JOURNAL_CANCEL_REQUEST = 'PARTIAL_JOURNAL_CANCEL_REQUEST',

  /**
   * Podpísanie dielčieho denníka
   */
  PARTIAL_JOURNAL_SIGN = 'PARTIAL_JOURNAL_SIGN',
}
