import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { Bozp } from '@models';
import { EvidenceApiUrl, JournalEntryState, Permission } from '@enums';
import { FILES } from '../bozp-hooked-sources';

export function useSignBOZPByTimestamp() {
  const { user, hasPermission } = useContext(UserContext);
  const {
    source: { data },
  } = useContext<DetailHandle<Bozp>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const fileCount = hookedSources[FILES].source.count;

  const callApi = (id: string) => {
    return abortableFetch(`${EvidenceApiUrl.JOURNAL_ENTRY_BOZP}/${id}/sign`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({
        type: 'TIMESTAMP',
        signedBy: { id: user?.person?.id },
        timestamp: new Date(),
      }),
    });
  };

  const showButton =
    (data?.awareness || !!fileCount) &&
    data?.journalEntry?.state === JournalEntryState.OPENED &&
    !!data?.briefedBy?.person &&
    hasPermission(Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_SIGN);

  return { callApi, showButton };
}
