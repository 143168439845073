import { useContext } from 'react';
import {
  DetailContext,
  SnackbarContext,
  SnackbarVariant,
  useWebsocketEffect,
} from '@eas/common-web';

const useFinalReportWebsocket = () => {
  const { refreshAll } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  useWebsocketEffect(() => {
    refreshAll();
    showSnackbar(
      'Záverečná správa bola úspešne vygenerovaná.',
      SnackbarVariant.SUCCESS
    );
  }, ['JOURNAL_CLOSED']);

  useWebsocketEffect(() => {
    refreshAll();
    showSnackbar('Chyba generovania záverečnej správy', SnackbarVariant.ERROR);
  }, ['JOURNAL_CLOSE_FAILED']);
};

export default useFinalReportWebsocket;
