import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  UserContext,
} from '@eas/common-web';
import { ATTACHMENT } from '@modules/journal-entry/journal-entry-hooked-sources';
import { useMultipleDeleteApi } from '@components/collection-field/collection-api';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useAttachmentsColumns } from './attachments-columns';
import { useValidationSchema } from './attachments-schema';
import {
  AttachmentsCreateDialog,
  DocumentsDialogText,
} from './dialogs/attachments-create-dialog';
import { AttachmentsReadDialog } from './dialogs/attachments-read-dialog';

type Props = {
  disabled?: boolean;
};

export function AttachmentsField({ disabled }: Props) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
    isLocked,
    mode,
  } = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const source = hookedSources[ATTACHMENT]?.source;

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__JOURNAL_ENTRY__ATTACHMENTS"
          defaultMessage="Prílohy"
        />
      }
      source={source}
      useColumns={useAttachmentsColumns}
      disabled={
        !hasPermission(
          Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_DETAIL
        ) ||
        mode !== DetailMode.VIEW ||
        isLocked ||
        !!disabled
      }
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_CREATE
        ),
        getUrl: () => `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment`,
        Dialog: AttachmentsCreateDialog,
        initValues: () => ({
          journalEntry: { id: data.id },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
        text: DocumentsDialogText,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/${collectionId}`,
        Dialog: AttachmentsReadDialog,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/${collectionId}`,
        Dialog: AttachmentsCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 600,
        text: DocumentsDialogText,
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryAttachment.JOURNAL_ENTRY_ATTACHMENT_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/attachment/${collectionId}`,
        label: 'Odobrať',
        //  useApi: useMultipleDeleteApi,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/${collectionId}`,
      }}
      // filters={filters}
      //  multiple
    />
  );
}
