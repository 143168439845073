import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl } from '@enums';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: 'Odkaz',
      width: 80,
      CellComponent: (props) => (
        <Redirect
          url={EvidenceBrowserUrl.JOURNAL_ENTRY_OTHER_RECORDS}
          id={props.value}
        />
      ),
    },
    {
      datakey: 'institutionName',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__COLUMNS__INSTITUTION_NAME',
        defaultMessage: 'Názov inštitúcie na stavbe',
      }),
      width: 200,
    },
    {
      datakey: 'header',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__HEADER',
        defaultMessage: 'Hlavička',
      }),
      width: 200,
    },
    {
      datakey: 'signed',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__COLUMNS__SIGNED',
        defaultMessage: 'Podpísané',
      }),
      width: 200,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__CREATED',
        defaultMessage: 'Vytvorenie',
      }),
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    // {
    //   datakey: 'createdBy.name',
    //   name: intl.formatMessage({
    //     id: 'ESD__OTHER_RECORDS_COLLECTION__COLUMN__CREATED',
    //     defaultMessage: 'Autor',
    //   }),
    //   width: 200,
    // },
    {
      datakey: 'description',
      name: intl.formatMessage({
        id: 'ESD__OTHER_RECORDS__DETAIL__FIELD_LABEL__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      width: 200,
    },
  ];
}
