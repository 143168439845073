import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import {
  DetailActionButton,
  DetailContext,
  Filter,
  ScrollableSource,
  TableFieldToolbarButton,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import { useMultipleDeleteApi } from '@components/collection-field/collection-api';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me, Timesheet } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { TimesheetCreateDialog } from './dialogs/timesheet-create-dialog';
import { TimesheetUpdateDialog } from './dialogs/timesheet-update-dialog';
import { useColumns } from './timesheet-columns';
import {
  useCreateValidationSchema,
  useUpdateValidationSchema,
} from './timesheet-schemas';

const DEFAULT_TIMESHEET_HOURS = 7.5;
const DEFAULT_TIMESHEET_OVERTIME = 0;

interface TimesheetsFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField2<Timesheet>>
  > {
  refFilters?: Filter[];
  currentJournalId: string;
  validityDate: string;
  source: ScrollableSource<Timesheet>;
}

export function TimesheetsField({
  currentJournalId,
  validityDate,
  source,
  ...overrides
}: TimesheetsFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  // const filters = useFilterFields();
  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__TIMESHEETS"
          defaultMessage="Osoby"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/timesheet/multiple',
        Dialog: TimesheetCreateDialog,
        title: 'Vytvorenie pomocou pracovnej skupiny.',
        text: 'Vyplňte formulár. Pre pridanie osoby freetextovo použite tlačidlo "Pridať - osoba" v predchádzajúcom kroku. Ďalšie tu vyplnené údaje sa pridajú pre každú vybranú osobu.',
        initValues: () => ({
          journalEntry: { id: currentJournalId },
          hours: DEFAULT_TIMESHEET_HOURS,
          overtime: DEFAULT_TIMESHEET_OVERTIME,
          date: validityDate,
        }),
        useSchema: useCreateValidationSchema,
        dialogWidth: 600,
        label: 'Pridať - pracovná skupina',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
        Dialog: TimesheetUpdateDialog,
        useSchema: useUpdateValidationSchema,
        dialogWidth: 600,
        title: 'Upravenie',
        text: 'Vyplňte formulár.',
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
        label: 'Odobrať',
        /* useApi: useMultipleDeleteApi */
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet/${collectionId}`,
      }}
      // filters={filters}
      customActions={[AddPersonAction]}
      {...overrides}
    />
  );
}

const AddPersonAction = ({
  id,
  disabled,
  handleError,
  selected,
}: {
  id: string;
  selectedItems: any[];
  selected: number[];
  disabled?: boolean;
  handleError: any;
}) => {
  const { hasPermission } = useContext(UserContext);
  const {
    source: { data },
  } = useContext(DetailContext);

  if (
    !hasPermission(
      Permission.JournalEntryTimesheet.JOURNAL_ENTRY_TIMESHEET_CREATE
    )
  ) {
    return <></>;
  }
  /*   const actionDisabled =
    disabled ||
    selected?.length === 0 ||
    _.get(selectedItems?.[0], 'allowed') === true; */
  return (
    <DetailActionButton
      key={`TIMESHEET_ADD_${id}`}
      promptKey={`TIMESHEET_ADD_${id}`}
      apiCall={(_, formData) => {
        /*   const itemId = _.get(selectedItems?.[0], 'id'); */
        return abortableFetch(`${EvidenceApiUrl.JOURNAL_ENTRY}/timesheet`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(formData),
        });
      }}
      buttonLabel="Pridať - Osoba"
      dialogTitle="Pridať osobu"
      dialogWidth={600}
      FormFields={TimesheetUpdateDialog}
      formValidationSchema={useUpdateValidationSchema() as any}
      formInitialValues={{
        journalEntry: { id: data?.id },
        date: data?.validityDate,
        hours: DEFAULT_TIMESHEET_HOURS,
        overtime: DEFAULT_TIMESHEET_OVERTIME,
      }}
      dialogText="Vyplňte formulár"
      ButtonComponent={({ onClick, label }) => (
        <TableFieldToolbarButton
          IconComponent={PlusOneIcon}
          show={true}
          disabled={disabled || selected.length > 0}
          title={label}
          onClick={onClick}
          color="secondary"
          variant="contained"
        >
          <Typography variant="body2">{label}</Typography>
        </TableFieldToolbarButton>
      )}
      //modes={[DetailMode.VIEW, DetailMode.EDIT]}
      onError={handleError}
    />
  );
};
