/**
 * Dictionary - Profession permissions
 */
export enum PermissionProfession {
  /**
   * Prehľad profesií
   */
  PROFESSION_LIST = 'PROFESSION_LIST',

  /**
   * Detail profesie
   */
  PROFESSION_DETAIL = 'PROFESSION_DETAIL',

  /**
   * Vytvorenie profesie
   */
  PROFESSION_CREATE = 'PROFESSION_CREATE',

  /**
   * Editácia profesie
   */
  PROFESSION_UPDATE = 'PROFESSION_UPDATE',

  /**
   * Zmazanie profesie
   */
  PROFESSION_DELETE = 'PROFESSION_DELETE',

  /**
   * Zneplatnenie profesie
   */
  PROFESSION_INVALIDATE = 'PROFESSION_INVALIDATE',

  /**
   * Obnovenie profesie
   */
  PROFESSION_REVALIDATE = 'PROFESSION_REVALIDATE',

  /**
   * Prevzatie profesii
   */
  PROFESSION_TAKEOVER = 'PROFESSION_TAKEOVER',

  /**
   * Prevzatie profesii
   */
  PROFESSION_IMPORT = 'PROFESSION_IMPORT',
}
