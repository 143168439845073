import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Investor } from '@models';

export function useColumns(): TableColumn<Investor>[] {
  const intl = useIntl();
  const { columnName, columnOrder, columnActive } =
    DictionaryEvidence.useDictionaryColumns<Investor>();

  return [
    columnName,
    {
      datakey: 'ico',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__ICO',
        defaultMessage: 'IČO',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },
    {
      datakey: 'dic',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__DIC',
        defaultMessage: 'DIČ',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
    },
    /*     {
      datakey: 'tenant',
      filterkey: 'tenant.id',
      sortkey: 'tenant.name',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__TENANT',
        defaultMessage: 'Vlastník',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    }, */
    {
      datakey: 'address.region',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__ADDRESS_REGION',
        defaultMessage: 'Kraj',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'address.municipality',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__ADDRESS_MUNICIPALITY',
        defaultMessage: 'Obec',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'address.street',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__ADDRESS_STREET',
        defaultMessage: 'Ulica',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'address.zipCode',
      name: intl.formatMessage({
        id: 'ESD_INVESTOR__COLUMN__ADDRESS_ZIPCODE',
        defaultMessage: 'PSČ',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    columnOrder,
    columnActive,
  ];
}
