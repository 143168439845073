import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DomainObject,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { WorkGroup } from '@models';
import { EvidenceApiUrl } from '@enums';
import { PersonAssignDialog } from './dialogs/person-assign-dialog';
import { useColumns } from './work-group-columns';
import { useValidationSchema } from './work-group-schema';

export const WorkGroupField = () => {
  const {
    source: { data },
  } = useContext<DetailHandle<WorkGroup>>(DetailContext);

  const useCreateApi = <T extends DomainObject>(
    getUrl: (id: string) => string
  ) => {
    const callApi = useEventCallback((id: string, formData: T) => {
      return abortableFetch(`${getUrl(id)}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          ...data,
          members: [...(data?.members ?? []), formData?.members ?? []],
        }),
      });
    });

    return {
      callApi,
    };
  };
  /*   const useReadApi = () => {
    const callApi = useEventCallback((id: string) => {
      return {
        async json() {
          return data
            ? Promise.resolve(data)
            : Promise.reject(new Error('Certifikace nenalezena'));
        },
      } as AbortableFetch;
    });

    return {
      callApi,
    };
  }; */
  const useDeleteApi = (
    getUrl: (id: string, collectionId: string | string[]) => string
  ) => {
    const callApi = useEventCallback(
      (id: string, collectionId: string | string[]) => {
        return abortableFetch(`${getUrl(id, collectionId)}`, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            ...data,
            members: data?.members?.filter((m) => m.id !== collectionId?.[0]),
          }),
        });
      }
    );

    return {
      callApi,
    };
  };

  return (
    <CollectionField2
      items={data?.members ?? []}
      disabled={false}
      title="Členovia"
      useColumns={useColumns}
      createAction={{
        getUrl: (id: string) => EvidenceApiUrl.WORK_GROUP + '/' + id,
        useApi: useCreateApi,
        Dialog: PersonAssignDialog,
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.WORK_GROUP}/${data?.id}`,
        //    useApi: useReadApi,
      }}
      updateAction={{
        getUrl: () => `${EvidenceApiUrl.WORK_GROUP}/${data?.id}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (id) => `${EvidenceApiUrl.WORK_GROUP}/${id}`,
        label: 'Odobrať',
        useApi: useDeleteApi,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.WORK_GROUP}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.WORK_GROUP}/${collectionId}`,
      }}
    />
  );
};
