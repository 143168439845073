import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  FormLocalDateField,
  NavigationContext,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { getAvailableValidityDates } from '@composite/journal-entry-collection/dialogs/journal-entry-create-dialog';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { JournalEntry } from '@models';
import { EvidenceBrowserUrl, JournalTypeEnum } from '@enums';
import { useCloseJournalEntry } from './dialog-actions/close-journal-entry-hook';
import { useDuplicateJournalEntry } from './dialog-actions/duplicate-journal-entry-hook';
import { useSignJournalEntryByTimestamp } from './dialog-actions/sign-journal-entry-timestamp-hook';

export function JournalEntryActionbar() {
  const {
    source: { data },
  } = useContext<DetailHandle<JournalEntry>>(DetailContext);
  const { navigate } = useContext(NavigationContext);

  const { callApi: duplicateApiCall, showButton: showDuplicateButton } =
    useDuplicateJournalEntry();

  const { callApi: closeApiCall, showButton: showCloseButton } =
    useCloseJournalEntry();

  const {
    callApi: signByTimestampApiCall,
    showButton: showSignByTimestampButton,
  } = useSignJournalEntryByTimestamp();

  const { minDate, maxDate } = getAvailableValidityDates();

  const isParentJournalPrimary =
    data?.journal?.journalType === JournalTypeEnum.PRIMARY;

  return (
    <>
      {data?.journal?.id && (
        <TertiaryDetailActionbarButton
          label={
            isParentJournalPrimary
              ? 'Späť na hlavný denník'
              : 'Späť na dielčí denník'
          }
          isLocked={false}
          onClick={() =>
            navigate(
              `${
                isParentJournalPrimary
                  ? EvidenceBrowserUrl.PRIMARY_JOURNAL
                  : EvidenceBrowserUrl.PARTIAL_JOURNAL
              }/${data.journal?.id}`
            )
          }
        />
      )}

      {showDuplicateButton && (
        <DetailActionButton
          promptKey="DUPLICATE_ENTRY"
          dialogTitle={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__DUPLICATE__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__DUPLICATE__DIALOG_TEXT"
              defaultMessage="Skutočne chcete duplikovať denný záznam?"
            />
          }
          buttonLabel={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__DUPLICATE__BUTTON_LABEL"
              defaultMessage="Duplikovať"
            />
          }
          apiCall={duplicateApiCall}
          ButtonComponent={TertiaryDetailActionbarButton}
          isLocked={false}
          formInitialValues={{
            validityDate: new Date().toISOString(),
          }}
          FormFields={() => (
            <FormLocalDateField
              name="validityDate"
              label={
                <FormattedMessage
                  id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__VALIDITY_DATE"
                  defaultMessage="Dátum denného záznamu"
                />
              }
              required
              representation="instant"
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        />
      )}
      {showSignByTimestampButton && (
        <DetailActionButton
          promptKey="SIGN_ENTRY_BY_TIMESTAMP"
          dialogTitle={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__TIMESTAMP_SIGN__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__TIMESTAMP_SIGN__DIALOG_TEXT"
              defaultMessage="Skutočne chcete podpísať denný záznam?"
            />
          }
          buttonLabel={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__TIMESTAMP_SIGN__BUTTON_LABEL"
              defaultMessage="Podpísať časovou pečiatkou"
            />
          }
          apiCall={signByTimestampApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
          isLocked={false}
        />
      )}
      {showCloseButton && (
        <DetailActionButton
          promptKey="CLOSE_ENTRY"
          dialogTitle={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__CLOSE__DIALOG_TITLE"
              defaultMessage="Upozornenie"
            />
          }
          dialogText={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__CLOSE__DIALOG_TEXT"
              defaultMessage="Skutočne chcete uzavrieť denný záznam?"
            />
          }
          buttonLabel={
            <FormattedMessage
              id="ESD_JOURNAL_ENTRY__ACTIONBAR__CLOSE__BUTTON_LABEL"
              defaultMessage="Uzavrieť"
            />
          }
          apiCall={closeApiCall}
          ButtonComponent={SecondaryDetailActionbarButton}
        />
      )}
    </>
  );
}
