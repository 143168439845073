import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  EmptyComponent,
  Evidence,
  UserContext,
  getItemFactory,
} from '@eas/common-web';
import { Bozp, Me, PartialJournal, PrimaryJournal } from '@models';
import {
  EvidenceApiUrl,
  JournalEntryState,
  JournalTypeEnum,
  Permission,
} from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import useDetailSubtitle from '@utils/use-detail-subtitle';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { BozpActionbar } from './bozp-actionbar';
import { useColumns } from './bozp-columns';
import BozpDetailRemoveButton from './bozp-detail-remove-button';
import { Fields } from './bozp-fields';
import { useBozpHookedSources } from './bozp-hooked-sources';
import { useValidationSchema } from './bozp-schema';

export const BOZP = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const subtitle = useDetailSubtitle();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE],
      REMOVE: [],
      REFRESH: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DETAIL],
      SHARE: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DETAIL],
      CHECK: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE],
      SAVE: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE],
      CLOSE: [Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<Bozp>({
    version: 1,
    identifier: 'BOZP',
    switcherProps: { midPoint: 0 },
    apiProps: {
      url: EvidenceApiUrl.JOURNAL_ENTRY_BOZP,
      getItem: getItemFactory<Bozp>({
        postProcess: (bozp) => {
          const journal = bozp?.journalEntry?.journal;
          const journalType = bozp?.journalEntry?.journal?.journalType;

          if (journalType === JournalTypeEnum.PARTIAL) {
            const partialJournal = journal as PartialJournal;

            handleSwitchSupplier(partialJournal.supplier?.id);
          } else if (journalType === JournalTypeEnum.PRIMARY) {
            const primaryJournal = journal as PrimaryJournal;

            handleSwitchSupplier(primaryJournal?.contractor?.id);
          }

          return bozp;
        },
      }),
      hookedSources: useBozpHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__TITLE',
        defaultMessage: 'BOZP',
      }),
      ToolbarComponent: EmptyComponent,
    },
    detailProps: {
      FieldsComponent: Fields,
      validationSchema,
      lock: (v) => {
        if (v?.journalEntry?.state !== JournalEntryState.OPENED)
          return 'Denný záznam nie je otvorený.';

        if (v?.signed)
          return 'BOZP je podpísaný. Možno iba pridávať oboznámené osoby a podpisovať.';

        return false;
      },
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__BOZP__DETAIL__TITLE',
          defaultMessage: 'BOZP',
        }),
        disableBtn: (button) =>
          ['EDIT'].includes(button) && (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: BozpActionbar,
        subtitle,
        After: () => (
          <BozpDetailRemoveButton
            type="BOZP"
            directDeletePermission={
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DELETE
            }
            requestDeletePermission={
              Permission.JournalEntryBOZP.JOURNAL_ENTRY_BOZP_DELETE_REQUEST
            }
          />
        ),
      },
    },
  });

  return <Evidence {...evidence} />;
};
