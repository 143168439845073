import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormTextField,
} from '@eas/common-web';

export const Fields = () => {
  return (
    <>
      <DictionaryEvidence.FieldOrder />
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ESD__WORK_CATEGORY__FIELD_LABEL__NAME"
            defaultMessage="Názov kategórie prác"
          />
        }
        required
      />
      <FormTextField
        name="externalId"
        label={
          <FormattedMessage
            id="ESD__TBPP__DETAIL__FIELD_LABEL__EXTERNAL_ID"
            defaultMessage="Externý identifikátor"
          />
        }
        disabled
      />

      <DictionaryEvidence.FieldActive />
      <AuthoredEvidence.AuthoredFields />
    </>
  );
};
