import { useIntl } from 'react-intl';
import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Bozp } from '@models';

export const useColumns = (): TableColumn<Bozp>[] => {
  const intl = useIntl();
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Bozp>();

  return [
    {
      datakey: 'header',
      name: intl.formatMessage({
        id: 'ESD__BOZP__TABLE__COLUMN__HEADER',
        defaultMessage: 'Hlavička',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      filterable: true,
      sortable: true,
    },
    ...authoredColumns,
  ];
};
